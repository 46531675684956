import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from "../utils/urls"

export default class StateService {
    async getStates() {
        const response = await axios.get(`${BACKEND_URL}/api/states`)
        if (response.status === 200 && response.data) {
            const resData = await response.data;
            // console.log(resData)
            return resData;
        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async insertStates(data) {
        try{
            let reqOptions = {
                url: `${BACKEND_URL}/api/states`,
                method: "POST",
                data: data,
            }
    
            let response = await axios.request(reqOptions);
            if (response.status === 201 && response.data) {
                const resData = await response.data;
                return resData;
    
            } else {
                throw json(
                    { message: 'Could not fetch events.' },
                    {
                        status: 500,
                    }
                );
            }

        }catch(error){
            console.log(error.response.data.error)
            let errorMessage = '';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error.message;
            }
    
            throw new Error(errorMessage);
        }
    }
}

export const { getStates, insertStates } = new StateService();