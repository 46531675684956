export const existingObgynTags = {
    "Just For You Women's Healthcare": "obgyn: just for women's healthcare",
    "Women's Specialists of Northside Gwinnett": "obgyn: women's sng",
    "Magic City OBGYN": "obgyn: magic city",
    "Cartersville OBGYN": "obgyn: cartersville",
    "Mental Health Screening Assessment": "obgyn: small clinic",
    "Cone Health Center for Women's Healthcare at Stoney Creek": "obgyn: cone health stoney creek",
    "Cone Health Center for Women's Healthcare at Family Tree": "obgyn: cone health family tree",
    "Cone Health Center for Women's Healthcare at MedCenter High Point": "obgyn: cone health femina medcenter high",
    "Cone Health Center for Women's Healthcare at Kernersville": "obgyn: cone health kernersville",
    "Cone Health Center for Women's Healthcare at Drawbridge Parkway": "obgyn: cone health drawbridge parkway",
    "Miami Femwell Health": "obgyn: miami"
}

export const existingPhq9Gad7Tags = {
    "Just For You Women's Healthcare": "phq9-gad7: just for women's healthcare",
    "Women's Specialists of Northside Gwinnett": "phq9-gad7: women's sng",
    "Magic City OBGYN": "phq9-gad7: magic city",
    "Reproductive Endocrinology and Infertility Group": "phq9-gad7: reproductive eig",
    "Children's Medical Group": "phq9-gad7: cmg",
    "South Tampa Gynecology": "phq9-gad7: south tg",
    "Mental Health Screening Assessment": "phq9-gad7: small clinic",
    "Miami Femwell Health": "phq9-gad7: miami",
    "Sales Jill": "sales: Jill",
    "Sales Michelle": "sales: Michelle",
    // Add more clinic entries as needed
  }
  