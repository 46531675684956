import React, { useState, useEffect } from "react";
import "../styles/Details.css";
import { Table, Spin, Tag, Modal, Input, Button } from "antd";
import useDebounce from '../hooks/useDebounce';
import ParticipationMatrixService from '../services/ParticipationMatrixService';
import UploadCredentialingSummaryForm from '../components/UploadCredentialingSummaryForm';
const { Column } = Table;
const { Search } = Input;

function ParticipationMatrix() {
    document.title = "LunaJoy Dashboard";
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(1000);
    const [totalProviders, setTotalProviders] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalItems, setTotalItems] = useState(1);
    const [isUploadModalOpen, setUploadModalOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState("");
    const [modalComponent, setModalComponent] = useState();

    const handleOk = () => {
        setUploadModalOpen(false);
    };

    const handleCancel = () => {
        setUploadModalOpen(false);
    };

    const handleUpload = () => {
        setUploadModalOpen(true);
        setModalTitle('Upload Credentialing Summary')
        setModalComponent(<UploadCredentialingSummaryForm setUploadModalOpen={setUploadModalOpen} setModalTitle={setModalTitle} setModalComponent={setModalComponent} />)
    }

    async function loadData(pageNo, pageSize) {
        const input = {};

        input["page"] = 1;
        input["limit"] = 10000;

        if (searchTerm !== "") {
            input["search"] = searchTerm;
        }

        try {
            setLoading(true);
            const details = await ParticipationMatrixService.getParticipationMatrixData(input);
            setTotalProviders(
                details.pagination.totalRecords || 0
            );

            if (details.data !== null) {
                // Flatten the data into rows
                const flattenedData = details.data.flatMap(provider =>
                    provider?.participation?.map((stateInfo, index) => ({
                        key: `${provider.key}-${index}`,
                        provider: provider.provider,
                        state: stateInfo.state,
                        insurances: stateInfo.insurances,
                        providerRowSpan: index === 0 ? provider.participation.length : 0,
                    }))
                );

                // Apply pagination on the flattened data
                const paginatedData = flattenedData.slice(
                    (pageNo - 1) * pageSize,
                    pageNo * pageSize
                );

                setTotalItems(flattenedData.length)
                setData(paginatedData); // Set only the paginated rows to data
            }
        } catch (error) {
            Modal.confirm({
                title: "Error Message",
                content: error?.response?.data?.message || "Oops! Error in Fetching Providers data",
                okText: "Ok",
                onOk: async () => {
                    console.error("Error in Fetching Providers data");
                },
                cancelButtonProps: { style: { display: "none" } },
            });
        } finally {
            setLoading(false)
        }
    }

    // const handleTableChange = (pagination, filters) => {
    //     setCurrentPage(pagination.current);
    //     setPageLimit(pagination.pageSize);
    //     setData([]);
    //     loadData(pagination.current, pagination.pageSize);
    // };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const debouncedSearchValue = useDebounce(searchTerm);

    useEffect(() => {
        setCurrentPage(1);
        setData([])
        loadData(1, 200);
    }, [debouncedSearchValue]);

    return (
        <div id="dashboard-container">
            <div className="row styleFilterRow">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ marginTop: 5 }}>
                        <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>
                            Total Providers
                        </span>
                        <Tag>
                            <strong style={{ fontSize: 16 }}>{totalProviders}</strong>
                        </Tag>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                        <label htmlFor="search" className="labelStylingFilter" style={{ fontWeight: "bold" }}>
                            Search:
                        </label>
                        <Search
                            id="search"
                            placeholder="Search by Provider name, state or insurance"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onSearch={handleSearch}
                            allowClear
                            style={{ width: "25vw" }}
                        />
                    </div>

                    <Button onClick={handleUpload}>Upload Credentialing Summary</Button>
                </div>
            </div>

            <Spin spinning={loading}>
                <Table
                    dataSource={data}
                    pagination={false}
                    bordered={true}
                    // onChange={handleTableChange}
                    scroll={{ y: "65vh" }}
                >
                    <Column
                        title='Provider'
                        dataIndex='provider'
                        key='provider'
                        width={260}
                        render={(text, record, index) => {
                            const rowSpan = record.providerRowSpan; // Get rowSpan from data
                            return {
                                children: text,
                                props: { rowSpan: rowSpan }, // Apply rowSpan
                            }
                        }}
                    />
                    <Column
                        title='State'
                        dataIndex='state'
                        key='state'
                        width={200}
                    />
                    <Column
                        title='Insurances'
                        dataIndex='insurances'
                        key='insurances'
                        render={(insurances) => (insurances.map((insurance, index) => {
                            function getRandomColor() {
                                const colors = [
                                    "#3498db", // Light Blue
                                    "#e74c3c", // Red
                                    "#2ecc71", // Green
                                    "#f1c40f", // Yellow
                                    "#9b59b6", // Purple
                                    "#1abc9c", // Teal
                                    "#e67e22", // Orange
                                    "#34495e", // Dark Blue
                                    "#e84393", // Pink
                                    "#8e44ad", // Dark Purple
                                    "#f39c12", // Dark Yellow/Gold
                                    "#27ae60", // Emerald Green
                                    "#c0392b", // Dark Red
                                    "#2980b9"  // Ocean Blue
                                ];

                                const randomIndex = Math.floor(Math.random() * colors.length);
                                return colors[randomIndex];
                            }

                            return <Tag key={insurance} style={{ marginBlock: '4px', borderColor: getRandomColor(), padding: '3px 12px', borderWidth: '2px' }} >{insurance}</Tag>
                        })
                        )
                        }
                    />
                </Table>
            </Spin>

            <Modal
                styles={{ header: { textAlign: "center" } }}
                title={modalTitle}
                open={isUploadModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                centered={true}
                closeIcon={false}
            >
                {modalComponent}
            </Modal>
        </div>
    );
}

export default ParticipationMatrix;
