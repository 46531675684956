import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from "../utils/urls"

export default class UtilService {

    async addZipCode(data) {
        console.log(BACKEND_URL)
        let headersList = {
            "Content-Type": "application/json"
        }

        let reqOptions = {
            url: `${BACKEND_URL}/api/addAmdZipCodes`,
            method: "POST",
            headers: headersList,
            data: data,
        }

        let response = await axios.request(reqOptions);
        console.log(response, 'zip response')
        if (response.status === 200 && response.data) {
            const resData = await response.data;
            console.log(resData)
            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }
}

export const { addZipCode } = new UtilService();