import { useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import validateField from '../../../../utils/validation'
import FieldError from '../FieldError'
import CustomSelect from '../CustomSelect'
import './Select.css'

const subscription = {
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  touched: true,
  value: true
}
const validateFields = []

const Select = ({ defaultValue, label, name, options, schema }) => {
  const [mobileView, setmobileView] = useState(false)

  let width
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    window.innerWidth < 480 ? setmobileView(true) : setmobileView(false)
    width = 335
    if (mobileView) width = 225
  }, [])

  const { input, meta } = useField(name, {
    subscription,
    validate: schema && validateField(schema),
    validateFields,
    defaultValue
  })

  useEffect(() => {
    input.onChange(defaultValue)
  }, [defaultValue])

  return (
    <div className={`${!input.value && 'empty'} ${((meta.touched && meta.error) || (!meta.dirtySinceLastSubmit && meta.submitError)) && 'invalid'} select`}>
      <label htmlFor={input.name}>
        {label}
        <span style={{ color: 'red', marginBottom: '-5rem' }}>*</span>
      </label>
      <CustomSelect
        id={input.name}
        style={{
          width: { width }
        }}
        options={options?.map(({ label, value }) => (
          {
            value,
            label
          }
        ))}
        value={input.value || 'Select'}
        onChange={input.onChange}
      />
      <FieldError {...meta} />
    </div>
  )
}

export default Select
