import { memo } from 'react'

const FieldError = ({ dirtySinceLastSubmit, error, submitError, touched }) => (
  ((touched && error) || (!dirtySinceLastSubmit && submitError))
    ? <p style={{
      color: "#de5072",
      fontSize: "0.75rem",
    }}>{error || submitError}</p>
    : null
)

export default memo(FieldError)
