import { useEffect, useState } from 'react'
import { useField, useForm } from 'react-final-form'
import { z } from 'zod'
import Select from './Select/Select'

const schema = z.number({
  required_error: 'Please select the appointment type.'
})

const AppointmentTypeSelect = ({
  availableAppointmentTypes,
  defaultValue,
  setAppointmentType,
}) => {
  const form = useForm()
  const { input: { value: appointmentType } } = useField('appointmentType', { subscription: { value: true } })
  const [value, setValue] = useState()

  useEffect(() => {
    form.batch(() => {
      form.change('appointmentType')
      form.resetFieldState('appointmentType')
    })
  }, [form])

  useEffect(() => {
    if (defaultValue && availableAppointmentTypes?.length) {
      setValue(defaultValue)
    }
  }, [availableAppointmentTypes, defaultValue])

  useEffect(() => {
    if (appointmentType && availableAppointmentTypes?.length) {
      setAppointmentType(appointmentType)
    }
  }, [appointmentType, availableAppointmentTypes, setAppointmentType])


  return (
    <Select
      name="appointmentType"
      label="Appointment Type"
      options={availableAppointmentTypes}
      schema={schema}
      defaultValue={value}
    />
  )
}

export default AppointmentTypeSelect
