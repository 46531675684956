import React, { useState, useEffect } from "react";
import "../styles/Details.css";
import { Table, Tooltip, Spin, Tag, Modal, Button, Input, Form } from "antd";
import { getFormsTempDetailsWithFilter, reconcileFormTempSubmissions } from "../services/FormsSubmissionService";
import {
  formatDateForApi,
  formatDateForGeneralInfo,
  formatDateForIntake,
} from "../utils/dateFormat";
import useDebounce from '../hooks/useDebounce';
import UpdateGeneralInfoForm from '../components/IntakeProcess/UpdateGeneralInfoForm';
import { notify } from '../utils/notify';
import generate from '../utils/generatePatientMatchingErrorModalContent';
import dayjs from 'dayjs';
import { findGhlContact } from '../services/GHLService';
import { convertToTitleCase } from '../utils/stringUtils';
const { Column } = Table;
const { Search } = Input;

function FormTempDetails() {
  document.title = "LunaJoy Dashboard";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [form] = Form.useForm()
  const dateFormat = 'MM-DD-YYYY'

  const updateTooltip = `Update the Personal Information in Assessment`
  const cannotReconcileTooltip = `No matching record found in GHL for this patient`

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination.current);
    setPageLimit(pagination.pageSize);
    setData([]);
    data.length = 0;
    loadData(pagination.current, pagination.pageSize);
  };

  const debouncedSearchValue = useDebounce(searchTerm);

  useEffect(() => {
    setCurrentPage(1);
    setPageLimit(10);
    setData([])
    data.length = 0;
    loadData(1, 10);
  }, [debouncedSearchValue]);

  async function loadData(pageNo, pageSize) {
    const input = {};

    input["page"] = pageNo;
    input["limit"] = pageSize;

    if (searchTerm !== "") {
      input["searchTerm"] = searchTerm;
    }

    try {
      setLoading(true);
      const details = await getFormsTempDetailsWithFilter(input);
      setTotalItems(details.data.FormTempSubmissionsCountByFilters.totalRecords || 0);
      if (details.data.FormTempSubmissionsCountByFilters.data !== null) {
        setData(
          details.data.FormTempSubmissionsCountByFilters.data.map((data) => {
            return {
              firstName: data?.firstName,
              lastName: data?.lastName,
              patientName: convertToTitleCase(data?.firstName) + ' ' + convertToTitleCase(data?.lastName),
              phone: data?.phone === "00000000" ? "--" : data?.phone,
              dob: data?.dob ? formatDateFromTimestamp(data.dob) : " ",
              email: data?.email.includes("notfound") ? "--" : data?.email,
              mismatchReason: data?.mismatchReason,
              id: data?.id
            };
          })
        );
      }
    } catch (e) {
      console.error(e);
      Modal.confirm({
        title: "Error Message",
        content: "Oops! Error in Fetching data",
        okText: "Ok",
        onOk: async () => {
          console.error("Error in Fetching forms temp data");
        },
        cancelButtonProps: { style: { display: "none" } },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
  }

  function formatDateFromTimestamp(timestamp) {
    const date = new Date(parseInt(timestamp, 10));
    return formatDateForGeneralInfo(date);
  }


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const setFormsFieldValues = (data) => {
    form.setFieldValue('firstname', data?.firstName)
    form.setFieldValue('lastname', data?.lastName)
    if (!data?.dob) {
      form.setFieldValue('dob', '', dateFormat)
    } else {
      form.setFieldValue('dob', dayjs(formatDateForApi(data?.dob), dateFormat))
    }
    form.setFieldValue('phone_no', data?.phone?.slice(2))
    form.setFieldValue('email', data?.email)
    form.setFieldValue('id', data?.id)
  }

  const handleUpdate = async (data) => {
    try {
      setModalTitle('')
      setModalComponent(
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '1rem'
          }}
        >
          <Spin />
          <p>Reconciling the Assessment...</p>
        </div>
      )

      const formData = {}
      formData.firstName = data.firstname
      formData.lastName = data.lastname
      formData.dob = formatDateForIntake(data.dob.$d)
      formData.phone = `+1${data.phone_no}`
      formData.email = data.email
      formData.id = data.id

      const contact = await findGhlContact(formData)

      if (contact.message && !contact.existingPatients) {
        const { title, content } = generate(contact.message)
        Modal.error({
          title,
          content: <>{content}</>,
          okText: 'OK',
          centered: true,
          okButtonProps: { style: { width: 60 } }
        })
      } else if (contact.existingPatients && !contact.message) {
        const result = await reconcileFormTempSubmissions({
          id: formData.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          dob: formData.dob,
          email: formData.email,
          intent: 'reconcile'
        })

        if (result) {
          notify('Reconciled the assessment successfully.', '')
        } else {
          notify('Error occured while Reconciling the assessment.', '', 'error')
        }
      } else if (!contact.message) {
        const result = await reconcileFormTempSubmissions({
          id: formData.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          dob: formData.dob,
          email: formData.email,
          intent: 'reconcile'
        })

        if (result) {
          notify('Reconciled the assessment successfully.', '')
        } else {
          notify('Error occured while Reconciling the assessment.', '', 'error')
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      form.resetFields()
      setIsModalOpen(false)
      loadData(1, 10)
    }
  }

  const handleCannotReconcile = async () => {
    const formValues = form.getFieldsValue()
    try {
      setModalTitle('')
      setModalComponent(
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '1rem'
          }}
        >
          <Spin />
          <p>Reconciling the Assessment...</p>
        </div>
      )

      const formData = {}
      formData.firstName = formValues.firstname
      formData.lastName = formValues.lastname
      formData.dob = formatDateForIntake(formValues.dob.$d)
      formData.phone = `+1${formValues.phone_no}`
      formData.email = formValues.email
      formData.id = formValues.id

      const result = await reconcileFormTempSubmissions({
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        dob: formData.dob,
        email: formData.email,
        intent: 'cannot reconcile'
      })

      if (result) {
        notify('Reconciled the assessment successfully.', '')
      } else {
        notify('Error occured while Reconciling the assessment.', '', 'error')
      }
    } catch (error) {
      console.error(error)
    } finally {
      form.resetFields()
      setIsModalOpen(false)
      loadData(1, 10)
    }
  }

  const editContent = (record) => {
    setIsModalOpen(true)
    setFormsFieldValues(record)
    setModalTitle('Reconcile Patient Information')
    setModalComponent(<UpdateGeneralInfoForm loading={loading} form={form} handleUpdate={handleUpdate} handleEditCancel={handleCancel} updateTooltip={updateTooltip} cannotReconcileTooltip={cannotReconcileTooltip} handleCannotReconcile={handleCannotReconcile} />)
  }

  return (
    <div id="dashboard-container">
      <div className="row styleFilterRow">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: 5 }}>
            <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>
              Total Assessments
            </span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{totalItems}</strong>
            </Tag>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <label htmlFor="search" className="labelStylingFilter" style={{ fontWeight: "bold" }}>
              Search Patient:
            </label>
            <Search
              id="search"
              placeholder="Search by First name, Last name, email or phone number"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onSearch={handleSearch}
              allowClear
              style={{ width: "25vw" }}
            />
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <Table
          dataSource={data}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: pageLimit,
          }}
          bordered={true}
          onChange={handleTableChange}
          scroll={{ y: "65vh" }}
        >
          <Column
            title="Patient Name"
            dataIndex="name"
            key="name"
            render={(text, record) => (
              <div>
                <strong>{record.patientName}</strong>
              </div>
            )}
          />
          <Column
            title="Phone"
            dataIndex="phone"
            key="phone"
            width={200}
            render={(text, record) => (
              <div>
                {record.phone}
              </div>
            )}
          />
          <Column
            title="Date of Birth"
            dataIndex="dob"
            key="dob"
            width={200}
            render={(text, record) => (
              <div>
                {record.dob}
              </div>
            )}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            render={(text, record) => (
              <div>
                {record.email}
              </div>
            )}
          />
          <Column
            title="Mismatch Reason"
            dataIndex="mismatchReason"
            key="mismatchReason"
            render={(mismatchReason) => {
              const parseReason = (reason) => {
                return reason.split(', ').map((item) => {
                  const [key, value] = item.split(': ')
                  return { key, value }
                })
              }

              const parsedMismatchReason = parseReason(mismatchReason)

              return (
                <div>
                  {parsedMismatchReason.map(({ key, value }) => (
                    <Tag color={value === "Matched" ? "success" : "error"} key={key} style={{ marginBlock: '4px' }}>
                      {key}
                    </Tag>
                  ))}
                </div>
              );
            }}
          />
          <Column
            title="Action"
            dataIndex="id"
            key="id"
            width={160}
            render={(id, record) => (
              <>
                <Tooltip title={"Reconcile Assessment"}>
                  <Button
                    onClick={() => editContent(record)}
                    style={{ cursor: "pointer" }}
                  >Reconcile
                  </Button>
                </Tooltip>
              </>
            )}
          />
        </Table>
      </Spin>

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
        centered={true}
      >
        {modalComponent}
      </Modal>
    </div>
  );
}

export default FormTempDetails;
