import React, { useCallback, useEffect, useState } from "react"
import { Button, Modal, Spin } from "antd"
import { setErrorMap } from "zod"
import { customErrorMap } from "../../../utils/validation"
import BookingCalendar from "./BookingCalender"
import "./BookFollowup.css"
import Row from "./Row"
import Column from "./Column"
import { Form } from "react-final-form"
import IntakeService from "../../../services/IntakeService"
import AppointmentTypeSelect from "./AppointmentTypeSelect"
import { notify } from "../../../utils/notify"
import { timezones } from "../../../assets/data/timeZones"
import { BACKEND_URL } from "../../../utils/urls"
import states from "../../../assets/data/states"

const BookFollowup = ({
  rowData,
  setIsBookFollowupModalOpen,
  setBookFollowupModalTitle,
  setBookFollowupModalContent,
}) => {
  const [appointmentTypes, setAppointmentTypes] = useState([])
  const [followupSlotsMedicaid, setFollowupSlotsMedicaid] = useState()
  const [followupSlotsTherapy, setFollowupSlotsTherapy] = useState()
  const [followupSlotsMDNPPA, setFollowupSlotsMDNPPA] = useState()
  const [providers, setProviders] = useState([])
  const [allApptTypes, setAllApptTypes] = useState([])
  const [appointmentTypeSelect, setAppointmentTypeSelect] = useState()
  const [bookFollowupLoading, setBookFollowupLoading] = useState(false)
  const [medicaidSlotsLoading, setMedicaidSlotsLoading] = useState(true)
  const [therapySlotsLoading, setTherapySlotsLoading] = useState(true)
  const [mdnpSlotsLoading, setMDNPSlotsLoading] = useState(true)
  const [timeZone, setTimeZone] = useState("")

  setErrorMap(customErrorMap)

  const handleBookFollowupCancel = useCallback(() => {
    setIsBookFollowupModalOpen(false)
  }, [setIsBookFollowupModalOpen])

  useEffect(() => {
    if (!rowData.state) {
      setBookFollowupLoading(false)
      setBookFollowupModalTitle("Update Patient's Address Info in GHL")
      setBookFollowupModalContent(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <ul style={{ fontSize: "16px" }}>
            <li>Cannot book follow-up: missing state in patient's address.</li>
            <li>Provide patient's full address, including state in GHL.</li>
            <li>Update address and refresh intake dashboard.</li>
            <li>Then, book follow-up appointment.</li>
          </ul>
          <Button className="cancelButton" onClick={handleBookFollowupCancel}>
            Close
          </Button>
        </div>
      )
      return
    }
  }, [
    handleBookFollowupCancel,
    rowData,
    setBookFollowupModalContent,
    setBookFollowupModalTitle,
  ])

  const getProviders = useCallback(async () => {
    try {
      const providers = await IntakeService.getProviders()
      setProviders(providers)
    } catch (error) {
      console.error(JSON.stringify(error))
    }
  }, [])

  const getAllApptTypes = useCallback(async () => {
    try {
      const allApptTypes = await IntakeService.getAllAppointmentTypes()
      setAllApptTypes(allApptTypes)
    } catch (error) {
      console.error(JSON.stringify(error))
    }
  }, [])

  const getTimeZone = useCallback(() => {
    const tz = timezones.find((tz) => tz.short === rowData?.state)
    setTimeZone(tz ? tz?.timezone : "")
  }, [rowData])

  useEffect(() => {
    getAllApptTypes()
    getProviders()
    getTimeZone()
  }, [getAllApptTypes, getProviders, getTimeZone])

  const fetchAvailableSlots = useCallback(async () => {
    setBookFollowupLoading(true)

    try {
      if (rowData && rowData.state) {
        // step:1 - get appt history
        const apptHistory = await IntakeService.getAllAppointment(rowData)
        const titleMappings = {
          "MED NEW": "Medication",
          "MED F/U": "Medication",
          "THER INDIV NEW": "Therapy",
          "THER INDIV F/U": "Therapy",
          "MD/NP/PA INTAKE": "MD/NP/PA",
          "MD/NP/PA F/U": "MD/NP/PA",
        }

        // step:2 - fetch latest medication, therapy, and MD/NP/PA appt, setApptTypeDetails
        const medicaidAppt = apptHistory.filter(
          (appt) =>
            appt?.type === "medication" &&
            appt.appt_status !== 11 &&
            appt.appt_status !== 10
        )
        const latestCreatedApptMedicaid = medicaidAppt.reduce(
          (latest, current) => {
            return new Date(current.createdAt) > new Date(latest.createdAt)
              ? current
              : latest
          },
          medicaidAppt[0]
        )

        const therapyAppt = apptHistory.filter(
          (appt) =>
            appt?.type === "therapy" &&
            appt.appt_status !== 11 &&
            appt.appt_status !== 10
        )
        const latestCreatedApptTherapy = therapyAppt?.reduce(
          (latest, current) => {
            return new Date(current.createdAt) > new Date(latest.createdAt)
              ? current
              : latest
          },
          therapyAppt[0]
        )

        const mdnpAppt = apptHistory.filter(
          (appt) =>
            (appt?.type === "MD/NP/PA" || appt?.appt_type.includes("MD/NP/PA")) &&
            appt.appt_status !== 11 &&
            appt.appt_status !== 10
        )

        const latestCreatedApptMDNPPA = mdnpAppt?.reduce(
          (latest, current) => {
            return new Date(current.createdAt) > new Date(latest.createdAt)
              ? current
              : latest
          },
          mdnpAppt[0]
        )


        if (!latestCreatedApptMedicaid && !latestCreatedApptTherapy && !latestCreatedApptMDNPPA) {
          setBookFollowupLoading(false)
          setIsBookFollowupModalOpen(false)
          notify(
            "Previous appointment either deleted or cancelled",
            "",
            "error"
          )
          return
        }

        // step:3 - Map appt_type values to their new names
        const updatedApptTypes = [
          {
            label: titleMappings[latestCreatedApptMedicaid?.appt_type],
            value: 4356,
          },
          {
            label: titleMappings[latestCreatedApptTherapy?.appt_type],
            value: 4341,
          },
          {
            label: titleMappings[latestCreatedApptMDNPPA?.appt_type],
            value: 5369,
          },
        ]

        let seen = new Set()
        let uniqueUpdatedApptTypes = updatedApptTypes?.filter((item) => {
          let duplicate = seen?.has(item.label + item.value)
          seen.add(item.label + item.value)
          return !duplicate
        })

        uniqueUpdatedApptTypes.sort((a, b) =>
          a?.label?.localeCompare(b?.label)
        )
        const filteredUniqueUpdatedApptTypes = uniqueUpdatedApptTypes.filter(
          (apptType) => apptType.label !== undefined
        )

        setAppointmentTypes(filteredUniqueUpdatedApptTypes)

        if (appointmentTypeSelect) {
          const apptType = filteredUniqueUpdatedApptTypes.find((appt) => appt.value === appointmentTypeSelect)?.label || filteredUniqueUpdatedApptTypes[0]?.label

          const patientState = states.find(
            (state) => state.value.toLocaleLowerCase() === rowData?.state?.toLocaleLowerCase()
          )

          const appointmentType = apptType
          const address = { state: patientState?.text }
          const appointmentCategory = "FollowUp"
          const source = "Intake"
          const baseUrl = `${BACKEND_URL}/api/getAvailableSlots/`

          // Construct the full URL without encodeURIComponent for the address
          const fullUrl = `${baseUrl}?appointmentType=${appointmentType}&address=${encodeURIComponent(
            JSON.stringify(address)
          )}&appointmentCategory=${appointmentCategory}&source=${source}`

          const response = await fetch(fullUrl, {
            method: "GET",
          })

          if (!response.ok) {
            console.error(
              "Error fetching available slots:",
              response.status,
              response.statusText
            )
            return
          }

          const data = await response.json()

          // Find the doctor in providers for Medicaid
          const matchedDoctorMedicaid = providers.filter(
            (provider) => provider.provider_column_heading?.trim() ===
              latestCreatedApptMedicaid?.column_heading?.trim()
          );

          // Find the doctor in providers for Therapy
          const matchedDoctorTherapy = providers.filter(
            (provider) =>
              provider.provider_column_heading?.trim() ===
              latestCreatedApptTherapy?.column_heading?.trim()
          );

          // Find the doctor in providers for MD/NP/PA
          const matchedDoctorMDNPPA = providers.filter(
            (provider) =>
              provider.provider_column_heading?.trim() ===
              latestCreatedApptMDNPPA?.column_heading?.trim()
          );

          // Available slots for Medicaid
          const availableSlotsMedicaid = data.slots.filter(
            (doctor) =>
              doctor.amdColumnId === matchedDoctorMedicaid[0]?.provider_column_id
          );

          // Available slots for Therapy
          const availableSlotsTherapy = data.slots.filter(
            (doctor) =>
              doctor.amdColumnId === matchedDoctorTherapy[0]?.provider_column_id
          );

          // Available slots for MD/NP/PA
          const availableSlotsMDNPPA = data.slots.filter(
            (doctor) =>
              doctor.amdColumnId === matchedDoctorMDNPPA[0]?.provider_column_id
          );

          // Set follow-up slots for Medicaid
          if (availableSlotsMedicaid.length) {
            setFollowupSlotsMedicaid(availableSlotsMedicaid[0]);
            setMedicaidSlotsLoading(false);
          } else {
            setMedicaidSlotsLoading(false);
          }

          // Set follow-up slots for Therapy
          if (availableSlotsTherapy.length) {
            setFollowupSlotsTherapy(availableSlotsTherapy[0]);
            setTherapySlotsLoading(false);
          } else {
            setTherapySlotsLoading(false);
          }

          // Set follow-up slots for MD/NP/PA
          if (availableSlotsMDNPPA.length) {
            setFollowupSlotsMDNPPA(availableSlotsMDNPPA[0]);
            setMDNPSlotsLoading(false);
          } else {
            setMDNPSlotsLoading(false);
          }
        }
        setBookFollowupLoading(false)
      }
    } catch (error) {
      console.error("Error fetching available slots:", error)
      setBookFollowupLoading(false)
      setMedicaidSlotsLoading(false)
      setTherapySlotsLoading(false)
    }
  }, [rowData, appointmentTypeSelect])

  useEffect(() => {
    fetchAvailableSlots()
  }, [fetchAvailableSlots])

  const onSubmit = async (data) => {
    try {
      setBookFollowupModalTitle("")
      setBookFollowupModalContent(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Spin />
          <p>Booking Follow-up Appointment...</p>
        </div>
      )

      const apptTypeData = allApptTypes.find(
        (apptType) => apptType.amdApptTypeId === data.appointmentType
      )
      const doctorSlotsData = appointmentTypeSelect === 4356
      ? followupSlotsMedicaid
      : appointmentTypeSelect === 5369 // ID for MD/NP/PA
        ? followupSlotsMDNPPA
        : followupSlotsTherapy

      const bookFollowupData = {
        selectedSlotDate: data?.selectedDate?.day,
        selectedSlotTime: data?.selectedDate?.time,
        appointmentDuration: apptTypeData?.duration,
        amdColumnId: doctorSlotsData?.amdColumnId,
        patientAMDId: rowData?.amdPatientID,
        providerProfileid: doctorSlotsData?.doctorProfileid,
        amdApptTypeId: apptTypeData?.amdApptTypeId,
        patientGHLId: rowData?.ghl_contact_id,
      }

      const myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/json")

      const baseUrl = `${BACKEND_URL}/api/bookFollowup`

      const response = await fetch(baseUrl, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(bookFollowupData),
        redirect: "follow",
      })

      // // if error
      if (!response.ok) {
        notify(
          "Oops! We encountered an error!",
          "Please try again after sometime!",
          "error"
        )
        return
      }

      notify("Follow-up appointment booked successfully.", "")
    } catch (error) {
      console.error(JSON.stringify(error))
    } finally {
      setIsBookFollowupModalOpen(false)
    }
  }

  return (
    <Spin spinning={bookFollowupLoading || (medicaidSlotsLoading && therapySlotsLoading)}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="formsFields">
              <Row>
                <Column>
                  <AppointmentTypeSelect
                    availableAppointmentTypes={appointmentTypes}
                    defaultValue={appointmentTypes[0]?.value}
                    setAppointmentType={setAppointmentTypeSelect}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={2}>
                  <BookingCalendar
                    followupSlots={
                      appointmentTypeSelect === 4356
                        ? followupSlotsMedicaid
                        : appointmentTypeSelect === 5369 // ID for MD/NP/PA
                          ? followupSlotsMDNPPA
                          : followupSlotsTherapy
                    }
                    timezone={timeZone}
                    duration={
                      appointmentTypeSelect === 4356 || appointmentTypeSelect === 5369
                        ? 30 // Medicaid duration
                          : 60 // Therapy duration
                    }
                  />
                </Column>
              </Row>
            </div>
            <div className="buttonContainer">
              <Button
                className="cancelButton"
                onClick={handleBookFollowupCancel}
              >
                Cancel
              </Button>
              <button type="submit" className="nextButton">
                Book
              </button>
            </div>
          </form>
        )}
      />
    </Spin>
  )
}

export default BookFollowup
