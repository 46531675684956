export function updateStateOfTagMaster(
  ghlTagMasters,
  setSourceTechnologyType,
  setSourceTechnology,
  setSourceOrganizationType,
  setSourceOrganizationName,
  setSpecialty
) {
  ghlTagMasters?.forEach((data) => {
    switch (data?.type) {
      case 'Source Technology Type':
        setSourceTechnologyType(data?.value)
        break
      case 'Source Technology':
        setSourceTechnology(data?.value)
        break
      case 'Source Organization Type':
        setSourceOrganizationType(data?.value)
        break
      case 'Source Organization Name':
        setSourceOrganizationName(data?.value)
        break
      case 'Specialty':
        setSpecialty(data?.value)
        break
      default:
        break
    }
  })
}
