import React, { useState } from "react";
import { Card, Col, Row, Button, Modal, Input, Select, Spin } from "antd";
import states from "../../assets/data/states";
import { insertStates } from "../../services/StateService";
const { Option } = Select;

function AddState() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);


    const handleSubmit = async () => {
        try {
            setLoading(true);
            setIsModalOpen(false);
            const response = await insertStates({ name, code })
            Modal.success({
                title: 'State added!',
                okText: 'OK',
                centered: true
            })
            setLoading(false);
        }catch(error) {
            console.log(error)
                Modal.error({
                    title: 'Error',
                    content: (
                        <div>
                            <p>Oops! Failed create new state</p>
                            {/* <p>{error.message}</p> */}
                        </div>
                    ),
                    okText: 'Ok',
                    cancelButtonProps: { style: { display: 'none' } }
                });
                setLoading(false);
        }
    
          
        
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Spin spinning={loading}>
            <div>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card title="Add State" bordered={false} style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                            <Button type="primary" onClick={() => {
                                setIsModalOpen(true)
                               
                            }}>
                                Add
                            </Button>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    title={modalTitle}
                    visible={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    maskClosable={true}
                    centered={true}
                    style={{ minHeight: "300px", fontSize: "1rem" }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', rowGap: '1rem' }}>
                        <Input
                            
                            placeholder="Enter state name"
                            value={name}
                            style={{ width: '300px' }}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Input
                          
                            placeholder="Enter state code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            style={{ width: '300px' }}
                        />
                        <Button type="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </Modal>
            </div>
        </Spin>
    );
}

export default AddState