import React from 'react'
import IntakeService from '../../services/IntakeService'
import { Modal, Table } from 'antd'
import { formatDateForAppointment } from '../../utils/dateFormat'
const { Column } = Table

export const viewCreditCardDetails = async (
  record,
  setIsModalOpen,
  setModalTitle,
  setIsPdfModal,
  setLoading,
  setModalComponent,
  setIsAppointmentModal
) => {
  try {
    setIsModalOpen(true)
    setModalTitle('PDF Viewer')
    setIsPdfModal(true)
    setLoading(true)
    setIsAppointmentModal(false)

    const fileBlob = await IntakeService.getDocument(record, 'CREDIT CARD AUTH')

    // Check if fileBlob is a Blob object
    if (!(fileBlob instanceof Blob)) {
      throw new Error('Invalid fileBlob type: not a Blob')
    }

    setModalComponent(<iframe src={URL.createObjectURL(fileBlob)} title="PDF Viewer" style={{ width: '100%', height: '600px', border: 'none' }} />)
  } catch (error) {
    console.error('Error occurred while processing the document:', error)
    // Handle the error appropriately, e.g., show an error message.
  } finally {
    setLoading(false)
  }
}

export const viewHippaConsentDetails = async (
  record,
  setIsModalOpen,
  setModalTitle,
  setIsPdfModal,
  setLoading,
  setModalComponent,
  setIsAppointmentModal,
  setIsInsuranceModal
) => {
  try {
    setIsModalOpen(true)
    setModalTitle('PDF Viewer')
    setIsPdfModal(true)
    setLoading(true)
    setIsAppointmentModal(false)
    setIsInsuranceModal(false)

    const fileBlob = await IntakeService.getDocument(record, 'CONSENT')

    // Check if fileBlob is a Blob object
    if (!(fileBlob instanceof Blob)) {
      throw new Error('Invalid fileBlob type: not a Blob')
    }

    setModalComponent(<iframe src={URL.createObjectURL(fileBlob)} title="PDF Viewer" style={{ width: '100%', height: '600px', border: 'none' }} />)
  } catch (error) {
    console.error('Error occurred while processing the document:', error)
    setIsModalOpen(false)
    setModalComponent(<></>)
    Modal.error({
      title: 'Error: Unable to get Hippa Consent',
      content: 'Please refer AMD for consents.',
      okText: 'Ok',
      cancelButtonProps: { style: { display: 'none' } },
      onOk: async () => {
        console.log('Unable to get Hippa Consent')
      }
    })
    // Handle the error appropriately, e.g., show an error message.
  } finally {
    setLoading(false)
  }
}

export const viewEmailAndSmsConsentDetails = async (
  record,
  setIsModalOpen,
  setModalTitle,
  setIsPdfModal,
  setLoading,
  setModalComponent,
  setIsAppointmentModal,
  setIsInsuranceModal
) => {
  try {
    setIsModalOpen(true)
    setModalTitle('PDF Viewer')
    setIsPdfModal(true)
    setLoading(true)
    setIsAppointmentModal(false)
    setIsInsuranceModal(false)

    const fileBlob = await IntakeService.getDocument(record, 'EMAILANDPHONECONSENT')

    // Check if fileBlob is a Blob object
    if (!(fileBlob instanceof Blob)) {
      throw new Error('Invalid fileBlob type: not a Blob')
    }

    setModalComponent(<iframe src={URL.createObjectURL(fileBlob)} title="PDF Viewer" style={{ width: '100%', height: '600px', border: 'none' }} />)
  } catch (error) {
    console.error('Error occurred while processing the document:', error)
    setIsModalOpen(false)
    setModalComponent(<></>)
    Modal.error({
      title: 'Error: Unable to get Email And Sms Consent',
      content: 'Please refer AMD for consents.',
      okText: 'Ok',
      cancelButtonProps: { style: { display: 'none' } },
      onOk: async () => {
        console.log('Unable to get EmailAndSmsConsent')
      }
    })
    // Handle the error appropriately, e.g., show an error message.
  } finally {
    setLoading(false)
  }
}

export const viewAllAppointmentHistory = async (
  record,
  setIsModalOpen,
  setModalTitle,
  setIsPdfModal,
  setLoading,
  setModalComponent,
  setIsAppointmentModal,
  setIsInsuranceModal
) => {
  try {
    setLoading(true)
    setIsModalOpen(true)
    setIsPdfModal(false)
    setIsAppointmentModal(true)
    setModalTitle('Patient Appointments')
    setIsInsuranceModal(false)

    const apptHistory = await IntakeService.getAllAppointment(record)
    if (!apptHistory) {
      throw new Error('There is no appointments')
    }

    const sortedApptHistory = [...apptHistory].sort((a, b) => {
      return a.appt_visit_start_date_time.localeCompare(b.appt_visit_start_date_time)
    })

    setModalComponent(
      <>
        <Table dataSource={sortedApptHistory} pagination={false} className="custom-apptHistory-table" tableLayout="fixed">
          <Column title="Appointment Date & Time" dataIndex="appt_visit_start_date_time" key="date" render={formatDateForAppointment} />
          <Column title="Appointment Type" dataIndex="appt_type" key="type" />
          <Column title="Clinician" dataIndex="column_heading" key="clinician" />
          <Column title="Status" dataIndex="appt_status_value" key="status" />
        </Table>
      </>
    )
  } catch (error) {
    console.error('Error occurred while getting the Appointment history :', error)
  } finally {
    setLoading(false)
  }
}
