import moment from "moment-timezone";

export function formatDate(date) {
  return moment.utc(date).format("MMM D, YYYY");
}

export function formatDateAndTime(date) {
  return moment.utc(date).format("MMM D, YYYY h:mm A");
}

export function formatDateForApi(data) {
  return moment(data).format("MM-DD-YYYY");
}

export function formatDateForGeneralInfo(data) {
  return moment(data).format("MM/DD/YYYY");
}

export function formatDateForAppointment(data) {
  return moment(data).format("MM-DD-YYYY HH:mm");
}

export function formatDateForIntake(data) {
  return moment(data).format("YYYY-MM-DD");
}

export function formatDateTimeForSql(dateTime) {
  return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateInlocal(date) {
  return moment.utc(date).local().format("MMM D, YYYY");
}

export function formatDateAndTimeInlocal(date) {
  return moment.utc(date).local().format("MMM D, YYYY h:mm A");
}

export function formateDateForFax(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}
export function formateDateForAuditlog(date) {
  return moment(date).format("YYYY-MM-DD 00:00:00");
}
export function formatTimeForBookingView(data) {
  return moment(data).format("00:00");
}
