import axios from 'axios';
import { BACKEND_URL } from '../utils/urls';
import { formatDateForApi } from '../utils/dateFormat';
const handleDashboardData = async (intent, startDate, endDate, targetValue, kpiValue, selectedEntityOption, selectedAppointmentTypeOption, selectedAppointmentStatusOption, pageNo, limit, searchField) => {

    // console.log(intent, startDate, endDate, targetValue, selectedEntityOption, selectedAppointmentTypeOption, selectedAppointmentStatusOption, 'service')
    console.log(BACKEND_URL)
    const url = `${BACKEND_URL}/kpi/appointments/targets`;
    const config = {
        headers: { 'Content-Type': 'application/json' },
        params: {
            pageNo: pageNo,
            limit: limit
        },
    };
    const payload = {
        intent: intent,
        payload: {
            startDate: formatDateForApi(startDate),
            endDate: formatDateForApi(endDate),
            apptType: selectedAppointmentTypeOption,
            isFollowUp: selectedEntityOption,
            apptStatus: selectedAppointmentStatusOption,
            setTargetValue: targetValue,
            setKpiValue: kpiValue,
            search: searchField
        },
        context: null
    };

    try {
        const response = await axios.post(url, payload, config);
        return response.data;
        // Handle the response data here
    } catch (error) {
        console.error('Error:', error);
        throw new Error('Oops! An error occurred while fetching dashboard data.');
        // Handle the error here
    }
};

export default handleDashboardData

