import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from "../utils/urls"

export default class InsuranceCaseManagerService {
    async getCaseManagerLinks() {

        const response = await axios.get(`${BACKEND_URL}/api/insuranceCaseManager/getCaseManagerLinks`)
        if (response.status === 200 && response?.data) {
            const resData = await response.data;

            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }
}

export const { getCaseManagerLinks } = new InsuranceCaseManagerService()
