import { Modal } from "antd";
import React from "react";

const CreateModal = ({ isCreateModalOpen, createModalContent, title }) => {
  return (
    <Modal
      styles={{ header: { textAlign: "center" } }}
      title={title}
      open={isCreateModalOpen}
      closeIcon={false}
      centered={true}
      footer={null}
    >
      {createModalContent}
    </Modal>
  );
};

export default CreateModal;
