import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from "../utils/urls"

export default class AWSErrorLogsService {
    async getAWSErrorLogs(logGroupId, startTime, endTime) {
        const url = `${BACKEND_URL}/api/awslogs/${logGroupId}/${startTime}/${endTime}`
        const config = {
            headers: { 'Content-Type': 'application/json' },
            params: {
                logGroupId: logGroupId ? logGroupId : null,
                startTime:  startTime ? startTime: null,
                endTime:  endTime ? endTime: null
            }
        };
        
        try {
            const response = await axios.get(url, config)
            if (response.status === 200 && response.data) {
                const resData = await response.data;
                return resData;
            } 
            return []
        } catch (error) {
            console.log(error)
            return []
        }
   
        
    }

    async getCloudwatchLogGroups() {
        const response = await axios.get(`${BACKEND_URL}/api/cloudwatchLogGroups`)
        console.log(response)
        if (response.status === 200 && response.data) {
            const resData = await response.data;
            return resData;

        } else {
            throw json(
                { message: 'Could not fetch log groups.' },
                {
                    status: 500,
                }
            );
        }
    }

}

export const { getAWSErrorLogs, getCloudwatchLogGroups } = new AWSErrorLogsService();