import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from '../utils/urls';
// import config from 'config';

export default class AuditLogsServices {

  static async getAuditLogs() {
    let reqOptions = {
      url: `${BACKEND_URL}/api/auditLogs`,
      method: "GET"
    }

    try {
      let response = await axios.request(reqOptions);

      if (response.status === 200 && response.data) {
        const resData = await response.data;
        return resData;

      } else {
        throw json({ message: 'Could not fetch events.' }, { status: 500 });
      }
    } catch {
      throw json({ message: 'Could not fetch events.' }, { status: 500 });
    }

  }

  static async getAuditLogsClinics(clinic, type, status, startDate, endDate, patientName = null, patientPhone = null) {
    let url = `${BACKEND_URL}/api/auditLogs`

    const config = {
      headers: { 'Content-Type': 'application/json' },
      params: {
        type: type ? type : null,
        status: status ? status : null,
        clinic: clinic ? clinic : null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        patientName: patientName,
        patientPhone: patientPhone
      }
     
    };

    try {
      let response = await axios.get(url, config);

      if (response.status === 200 && response.data) {
        const resData = await response.data;
        return resData;

      } else {
        throw json({ message: 'Could not fetch events.' }, { status: 500 });
      }
    } catch {
      throw json({ message: 'Could not fetch events.' }, { status: 500 });
    }

  }

  static async getDocument(record) {
    const url = `${BACKEND_URL}/api/auditLogs/${record.id}/document`;
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const windowUrl = window.URL.createObjectURL(blob);

      return windowUrl;
    } catch (error) {
      console.error('Error while downloading document: ', error);
    }
  }

  static async downloadDocument(record) {
    try {
      const windowUrl = await this.getDocument(record);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = windowUrl;
      a.download = `${record.clinic}-${record.channel}-document.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(windowUrl);

      return true;
    } catch (error) {
      console.error('Error while downloading document: ', error);
    }
  }

  static async handleRetry(id) {
    try {
      const response = await axios.post(`${BACKEND_URL}/api/auditLogs/${id}/retry`);
      //   console.log(response)
      if (response.status === 200 && response.data) {

        console.log(`Retry successful for ID ${id}`);

      } else {
        throw new Error('Retry failed.');
      }
    } catch (error) {
      console.error(`Retry failed for ID ${id}:`, error.message);

    }
  }

}