import { memo } from 'react'
import './Time.css'

const Time = ({ isSelected, setSelectedTime, time }) => {

  function handleButtonClick() {
    setSelectedTime(time)
  }

  return (
    <button type="button" onClick={handleButtonClick} className={`${isSelected && 'selectedTime'} time`}>{time}</button>
  )
}

export default memo(Time)
