import { Modal, Spin } from 'antd'
import React from 'react'

const ViewModal = ({ modalTitle, isModalOpen, handleCancel, isPdfModal, loading, modalComponent, isAppointmentModal, isInsuranceModal }) => {
  return (
    <Modal
      title={modalTitle}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      maskClosable={true}
      centered={true}
      width={isPdfModal || isAppointmentModal ? '50vw' : isInsuranceModal ? '70vw' : '520px'}
      style={{ minHeight: '300px', fontSize: '1rem' }}
    >
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin tip="Loading" size="large">
            <div style={{ minHeight: isAppointmentModal ? '100px' : '600px' }}></div>
          </Spin>
        </div>
      ) : (
        modalComponent
      )}
    </Modal>
  )
}

export default ViewModal
