import { memo } from 'react'
import { addMonths, format, subMonths } from 'date-fns'
import ArrowIcon from './ArrowIcon'
import './Header.css'

const Header = ({ date, setDate }) => {
  function handlePreviousButtonClick() {
    setDate(subMonths(date, 1))
  }

  function handleNextButtonClick() {
    setDate(addMonths(date, 1))
  }

  return (
    <div className='header'>
      <button type="button" onClick={handlePreviousButtonClick}>
        <ArrowIcon />
      </button>
      <p className="month">{format(date, 'MMMM yyyy')}</p>
      <button type="button" onClick={handleNextButtonClick}>
        <ArrowIcon />
      </button>
    </div>
  )
}

export default memo(Header)
