import React, { useState, useEffect, useRef } from "react";
import "../styles/Details.css";
import { DateRangePicker } from 'react-date-range';
import CalendarGrey from '../assets/Calendar Dark.svg'
import CalendarBlue from '../assets/Calendar Blue.svg'
import { SwapLeftOutlined, SwapRightOutlined, FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip, Spin, Card, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAthenaAppointment } from "../services/PartnerAppointmentService";
import { formatDateAndTimeInlocal } from "../utils/dateFormat";
import MusterSheet from "../components/MusterSheet";
import FilterComponent from "../components/FilterComponent";
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { notify } from "../utils/notify";
import useDebounce from "../hooks/useDebounce";
import XLSX from 'sheetjs-style';
const { Column } = Table;

function AthenaDetails() {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(1)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState();
  const [data, setData] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false)
  const navigate = useNavigate();

  const [isScoredOnly, setIsScoredOnly] = useState(false)
  const [isAvailableInAmd, setIsAvailableInAmd] = useState(false)
  const [appointmentStatus, setAppointmentStatus] = useState([])
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const datePickerRef = useRef(null);
  const buttonRef = useRef(null);

  const filterCardRef = useRef(null);
  const filterButtonRef = useRef(null);



  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination.current)
    setPageLimit(pagination.pageSize)
    setData([])
    data.length = 0
    loadData(startDate, endDate, pagination.current, pagination.pageSize);
  };

  const handleCardChange = (pagination, filters) => {
    setCurrentPage(pagination)
    setPageLimit(10)
    setData([])
    data.length = 0
    loadData(startDate, endDate, pagination, 10);
  };



  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);

    }

    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false);

    }
  };

  const getSixDaysAgoFromCurrentDate = () => {
    const today = new Date();
    const sixDaysAgo = new Date(today);
    sixDaysAgo.setDate(today.getDate() - 6);
    return sixDaysAgo;
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: getSixDaysAgoFromCurrentDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const startDate = dateRange[0].startDate
  const endDate = dateRange[0].endDate
  const momentFormatStartDate = moment(startDate).format("D/M/YYYY");
  const momentFormatEndDate = moment(endDate).format("D/M/YYYY");


  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection]);

  };

  const handleToggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };


  const debouncedSearchValue = useDebounce(searchField);


  useEffect(() => {
    setCurrentPage(1)
    setPageLimit(10)
    setData([])
    data.length = 0
    loadData(startDate, endDate, 1, 10);
  }, [startDate, endDate, debouncedSearchValue, appointmentStatus, isScoredOnly, isAvailableInAmd]);



  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  async function loadData(startDate, endDate, pageNo, pageSize) {
    try {
      setLoading(true)
      const details = await getAthenaAppointment(startDate, endDate, pageNo, pageSize, debouncedSearchValue, appointmentStatus, isScoredOnly, isAvailableInAmd)
      setTotalItems(details.totalItems === 0 ? 1 : details.totalItems)
      if (details.data.length) {
        setData(details.data.map((element) => {

          const date = new Date();
          const syncStatus = element.syncStatus.split(",");
          return {
            key: element.id,
            id: element.id,
            patientname: element.patientFirstName + " " + element.patientLastName,
            email: element.patientEmail.includes("notfound") ? "--" : element.patientEmail,
            phone: element.patientPhone === "00000000" ? "--" : element.patientPhone,
            clinic: element.clinic,
            clinician: element.clinicianName,
            carePartnerId: element.carePartnerId,
            isReferred: element.isReferred ? element.isReferred : false,
            appointmentId: element.appointmentId,
            patient: [element.patientFirstName, element.patientLastName, element.patientEmail.includes("notfound") ? "--" : element.patientEmail, element.patientPhone === "00000000" ? "--" : element.patientPhone],
            insurances: element.patientInsuranceDetails,
            assessmentResponseId: element.assessmentResponseId,
            assessment: element.assessment,
            epdsScore: element.assessment ? element.assessment.epdsScore || element.assessment.epdsScore === 0 ? element.assessment.epdsScore : null : null,
            gdsScore: element.assessment ? element.assessment.gdsScore || element.assessment.gdsScore === 0 ? element.assessment.gdsScore : null : null,
            gad7Score: element.assessment ? element.assessment.gad7Score || element.assessment.gad7Score === 0 ? element.assessment.gad7Score : null : null,
            phq9Score: element.assessment ? element.assessment.phq9Score || element.assessment.phq9Score === 0 ? element.assessment.phq9Score : null : null,
            insurancePolicyHolder: element.patientInsuranceDetails ? element.patientInsuranceDetails.insurancepolicyholder : null,
            ircName: element.patientInsuranceDetails ? element.patientInsuranceDetails.ircname : null,
            insurancePlanName: element.patientInsuranceDetails ? element.patientInsuranceDetails.insuranceplanname : null,
            insurancePackageId: element.patientInsuranceDetails ? element.patientInsuranceDetails.insurancepackageid : null,
            insuredAddress: element.patientInsuranceDetails ? element.patientInsuranceDetails.insuredaddress : null,
            appointmentdateandtime: element.appointmentDateAndTime,
            status:
              element.appointmentStatus === "Booked"
                ? new Date(element.appointmentDateAndTime) < date
                  ? "No Show"
                  : element.appointmentStatus
                : element.appointmentStatus,
            syncStatus: [syncStatus[0], syncStatus[1]],
            isAvailableInAmd: element.isAvailableInAmd ? "Yes" : "No",
            consentToCall: element.consentToCall ? "Yes" : "No",
            consentToText: element.consentToText ? "Yes" : "No"
          };
        }))
      }

    } catch (e) {
      console.log("failed to load data from database", e)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {
          console.log("Error in Fetching data");
        },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
    return true;
  }


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  function handleInsuranceDetails(insurance) {
    // const ins = JSON.parse(insurance)
    insurance = insurance ? insurance : {}
    setModalTitle("Insurance Details");
    setModalComponent(
      <div>
        <div>
          <strong>Insurance Holder Name :</strong>{" "}
          {insurance.insurancepolicyholder}
        </div>
        <div>
          <strong>IRC Name :</strong> {insurance.ircname}
        </div>
        <div>
          <strong>Insurance Plan Name : </strong>
          {insurance.insuranceplanname}
        </div>
        <div>
          <strong>Insured Holder DOB : </strong>
          {insurance.insurancepolicyholderdob}
        </div>
        <div>
          <strong>Insurance Group Id :</strong>{" "}
          {insurance.policynumber}
        </div>
        <div>
          <strong>Insured Member Id : </strong>
          {insurance.insuranceidnumber}
        </div>
      </div>
    );
    setIsModalOpen(true);
  }



  async function handleScreenerDetails(element, type) {

    setModalTitle(`${type} Details`);
    setModalComponent(

      <div>
        {element.epdsAnswers && type === "EPDS" && (
          <div>
            <p>EPDS Score: {element.epdsScore || element.epdsScore === 0 ? element.epdsScore : "Incomplete"}</p>
            <p>EPDS Answers:</p>
            <ul>
              {Object.entries(element.epdsAnswers).map(([question, answer]) => (
                <li key={question}>{question}: {answer}</li>
              ))}
            </ul>
          </div>
        )}
        {element.phq9Answers && type === "PHQ-9" && (
          <div>
            <p>PHQ-9 Score: {element.phq9Score || element.phq9Score === 0 ? element.phq9Score : "Incomplete"}</p>
            <p>PHQ-9 Answers:</p>
            <ul>
              {Object.entries(element.phq9Answers).map(([question, answer]) => (
                <li key={question}>{question}: {answer}</li>
              ))}
            </ul>
          </div>
        )}
        {element.gad7Answers && type === "GAD-7" && (
          <div>
            <p>GAD-7 Score: {element.gad7Score || element.gad7Score === 0 ? element.gad7Score : "Incomplete"}</p>
            <p>GAD-7 Answers:</p>
            <ul>
              {Object.entries(element.gad7Answers).map(([question, answer]) => (
                <li key={question}>{question}: {answer}</li>
              ))}
            </ul>
          </div>
        )}
        {element.gdsAnswers && type === "GDS" && (
          <div>
            <p>GDS Score: {element.gdsScore || element.gdsScore === 0 ? element.gdsScore : "Incomplete"}</p>
            <p>GDS Answers:</p>
            <ul>
              {Object.entries(element.gdsAnswers).map(([question, answer]) => (
                <li key={question}>{question}: {answer}</li>
              ))}
            </ul>
          </div>
        )}

        {!element.gdsAnswers && !element.gad7Answers && !element.phq9Answers && !element.epdsAnswers && <div>Incomplete Data</div>}

      </div>
    );
    setIsModalOpen(true);
  }

  // Will use for mobile card
  const renderMobileCard = (record) => {
    return (
      <div className="mobile-card" key={record.id}>
        <>
          <Link to={`/referrals?carePartner=${record.carePartnerId}`} style={{ color: "black", textDecoration: "none" }}>
            <p>{record.clinic}</p></Link>
        </>
        <p>Appointment Date: {record.appointmentdateandtime ? formatDateAndTimeInlocal(record.appointmentdateandtime) : " "}</p>
        <p>Patient Name: {record.patientname}</p>
        <p>Patient Email: {record.patient[2]}</p>
        <p>Patient Phone: {record.patient[3]}</p>
        <p>{record.isReferred ? <div><Link to={`/referrals?patient=${record.appointmentId}`} style={{ color: "green", textDecoration: "none" }}><strong>Referral</strong></Link></div> : null}</p>
        <p>Clinician: {record.clinician}</p>
        <p>Insurances: <a onClick={() => handleInsuranceDetails(record.insurances)} >Details</a></p>
        <p>Is Scored: <Tag color={record.isscored === "True" ? "green" : "red"} key={record.isscored}>
          {record.isscored}
        </Tag></p>
        <p>Screener Details: <>
          <a onClick={() => handleScreenerDetails(record.assessmentResponseId)}>
            Click here
          </a>
        </></p>
        <p>Status: <>
          <Tag
            color={
              record.status === "In Progress"
                ? "orange"
                : record.status === "Completed"
                  ? "green"
                  : record.status === "Cancelled"
                    ? "red"
                    : record.status === "No Show"
                      ? "geekblue"
                      : "cyan"
            }
            key={record.status}
          >
            {record.status}
          </Tag>
        </></p>
        <p>Sync Status: <Tooltip title={record.syncStatus[0]}>
          {record.syncStatus[0] === "Athena to LunaJoy" ? <SwapLeftOutlined style={{ fontSize: '20px' }} /> : <SwapRightOutlined style={{ fontSize: '20px' }} />}
          {record.syncStatus[1] === true ? " Completed" : " Pending"}
        </Tooltip></p>
      </div>
    );
  };

  // Use media queries to switch between table and card layouts
  const isMobileView = window.innerWidth <= 768;

  const handleFilter = () => {
    setIsFilterVisible(true);
  }

  const handleClose = () => {
    setIsFilterVisible(false);
  };


  const items = [
    {
      key: '1',
      label: 'pdf',
    },
    {
      key: '2',
      label: 'excel',
    }
  ]
  const generateExcel = (data) => {
    const columnsToInclude = ['patientname', 'email', 'phone', 'clinic', 'clinician', 'insurancePolicyHolder', 'ircName', 'insurancePlanName', 'insurancePackageId', 'insuredAddress', 'epdsScore', 'phq9Score', 'gad7Score', 'gdsScore'];

    // Filter the data to include only the specified columns
    const filteredData = data.map((item) => {
      // Create a new object with only the specified columns
      const filteredItem = {};
      columnsToInclude.forEach((column) => {
        filteredItem[column] = item[column];
      });
      return filteredItem;
    });

    const filetype =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: filetype });
  };

  const handleDownloadMuster = async (e) => {
    const foundItem = items.find(item => item.key === e.key);
    console.log(foundItem.label)
    setLoading(true);
    try {

      let blobData, fileExtension;

      if (foundItem.label === 'pdf') {
        // get ready the pdf
        let myPdf = <MusterSheet data={data} from={"Athena"} />;
        // use the pdf compoent in react-pdf to get the blod to download
        blobData = await pdf(myPdf).toBlob();
        fileExtension = '.pdf';
      } else {
        blobData = generateExcel(data);
        fileExtension = '.xlsx';
      }


      setLoading(false);
      //  save the pdf to user's local device
      saveAs(blobData, 'Athean_Connector_Appointment' + fileExtension);
      notify('Downloaded!', 'Please check your downloads!');
    } catch (error) {
      console.log(error);
    }

  };



  return (
    <div id="dashboard-container">
      <div className='row styleFilterRow'>
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
          <div style={{ marginTop: 5 }}>
            <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Appointments</span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : totalItems}</strong>
            </Tag>

          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span className='dateStyling' style={{ marginRight: '10px' }}>Filter</span>
            <div style={{ position: 'relative' }}>

              <Tooltip title="Filter" >
                <FilterOutlined
                  style={{ marginRight: "10px", fontSize: "18px", marginTop: "5px", cursor: "pointer" }}
                  ref={filterButtonRef}
                  onClick={handleFilter}
                />
              </Tooltip>

              {isFilterVisible && (
                <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}>
                  <Card title="Filter Options" bordered={true} extra={<Button type="Close" onClick={handleClose}><CloseCircleOutlined /></Button>}>
                    <FilterComponent searchField={searchField} setSearchField={setSearchField} selectedAtheanAppointmentStatus={appointmentStatus} setSelectedAthenaAppointmentStatus={setAppointmentStatus} isScoredOny={isScoredOnly} setIsScoredOnly={setIsScoredOnly} isAvailableInAmd={isAvailableInAmd} setIsAvailableInAmd={setIsAvailableInAmd} from={"Athena"} />
                  </Card>
                </div>
              )}
            </div>
            <span className='dateStyling'>{momentFormatStartDate + ' to ' + momentFormatEndDate}</span>
            <span ref={buttonRef} onClick={handleToggleDatePicker} className='marginAdjustCalendarToggleButton'>{showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}</span>
            {showDatePicker && (
              <div className="date-picker-container" ref={datePickerRef} style={{ position: 'absolute', zIndex: '9999', top: '10.5rem', display: 'flex', justifyContent: 'end', right: '30px' }}>
                <DateRangePicker
                  ranges={dateRange}
                  onChange={handleDateChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {
        isMobileView ? (<Spin spinning={loading}>
          {data.map((record) => renderMobileCard(record))}
          <Pagination
            total={totalItems}
            current={currentPage}
            pageSize={pageLimit}
            showSizeChanger={false}
            onChange={handleCardChange}
          /></Spin>) : */}
      <Spin spinning={loading}>
        <Table
          dataSource={data}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: pageLimit,

          }}
          bordered={true}
          onChange={handleTableChange}
        >
          <Column
            title="Referral Source"
            dataIndex="clinic"
            key="clinic"


            render={(clinic, record) => (
              <>
                <Link to={`/referrals?carePartner=${record.carePartnerId}`} style={{ color: "black", textDecoration: "none" }}>
                  <p>{record.clinic}</p></Link>
              </>
            )}
          />
          <Column
            title="Appointment Date"
            dataIndex="appointmentdateandtime"
            key="appointmentdateandtime"

            width={130}
            render={(appointmentdateandtime) => (
              <>
                <p>{appointmentdateandtime ? formatDateAndTimeInlocal(appointmentdateandtime) : " "}</p>
              </>
            )}
          />
          <Column

            title="Patient Name"
            dataIndex="patient"
            key="patient"

            render={(patient, record) => (
              <>
                <strong>{patient[0] + " " + patient[1]}</strong>
                <div>{patient[2]}</div>
                <div>{patient[3]}</div>
                {record.isReferred ? <div><Link to={`/referrals?patient=${record.appointmentId}`} style={{ color: "green", textDecoration: "none" }}><strong>Referral</strong></Link></div> : null}
              </>
            )}
          />

          <Column
            title="Clinician Name"
            dataIndex="clinician"
            key="clinician"


          />
          <Column

            title="Payment"
            dataIndex="insurances"
            key="insurances"
            render={(insurances) => (
              <>
                {insurances ? <div> {insurances.ircname !== "Self-Pay (cash)" && insurances.ircname !== "" ?
                  <Tooltip title="View Insurance Details"><Button onClick={() => handleInsuranceDetails(insurances)}>
                    Details
                  </Button> </Tooltip> : "Self-Pay"}</div> : <div>Self-Pay</div>}
              </>
            )}
          />


          <Column

            title="Assessment Form"
            dataIndex="assessment"
            key="assessment"
            render={(assessment) => (
              <>
                {assessment ? <div><a onClick={() => handleScreenerDetails(assessment, "EPDS")}>{assessment.epdsAnswers ? <div>EPDS : {assessment.epdsScore || assessment.epdsScore === 0 ? assessment.epdsScore : "Incomplete"}</div> : null}</a>
                  <a onClick={() => handleScreenerDetails(assessment, "GDS")}>{assessment.gdsAnswers ? <div>GDS : {assessment.gdsScore || assessment.gdsScore === 0 ? assessment.gdsScore : "Incomplete"}</div> : null}</a>
                  <a onClick={() => handleScreenerDetails(assessment, "PHQ-9")}>{assessment.phq9Answers ? <div>PHQ-9 : {assessment.phq9Score || assessment.phq9Score === 0 ? assessment.phq9Score : "Incomplete"}</div> : null}</a>
                  <a onClick={() => handleScreenerDetails(assessment, "GAD-7")}>{assessment.gad7Answers ? <div>GAD-7 : {assessment.gad7Score || assessment.gad7Score === 0 ? assessment.gad7Score : "Incomplete"}</div> : null}</a> </div> : <div>None</div>}
              </>
            )}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"

            render={(status) => (
              <>
                <Tag
                  color={
                    status === "In Progress"
                      ? "orange"
                      : status === "Completed"
                        ? "green"
                        : status === "Cancelled"
                          ? "red"
                          : status === "No Show"
                            ? "geekblue"
                            : "cyan"
                  }
                  key={status}
                >
                  {status}
                </Tag>
              </>
            )}
          />
          <Column
            title="Sync Status"
            dataIndex="syncStatus"
            key="sycnStatus"

            // filters={[{ text: "Athena to LunaJoy", value: "Athena to LunaJoy" }, { text: "LunaJoy to Athena", value: "LunaJoy to Athena" }]}
            // onFilter={((value, record) => record.syncStatus.indexOf(value) === 0)}
            render={(syncStatus) => (
              <div style={{ color: syncStatus[1] === "Completed" ? "green" : syncStatus[1] === "Pending" ? "orange" : "red" }}>
                <Tooltip title={syncStatus[0]}>
                  {syncStatus[0] === "Athena to LunaJoy" ? <SwapLeftOutlined style={{ fontSize: '20px' }} /> : <SwapRightOutlined style={{ fontSize: '20px' }} />}
                  <div>{syncStatus[1]}</div>
                </Tooltip>
              </div>
            )}
          />
          <Column
            title="Available In Amd"
            dataIndex="isAvailableInAmd"
            key="isAvailableInAmd"

            render={(isAvailableInAmd) => (
              <>
                <Tag
                  color={
                    isAvailableInAmd === "Yes" ? "green" : "red"

                  }
                  key={isAvailableInAmd}
                >
                  {isAvailableInAmd}
                </Tag>
              </>
            )}
          />

          <Column
            title="Consent to Call"
            dataIndex="consentToCall"
            key="consentToCall"

            render={(consentToCall) => (
              <>
                <Tag
                  color={
                    consentToCall === "Yes" ? "green" : "red"

                  }
                  key={consentToCall}
                >
                  {consentToCall}
                </Tag>
              </>
            )}
          />

          <Column
            title="Consent to Text"
            dataIndex="consentToText"
            key="consentToText"

            render={(consentToText) => (
              <>
                <Tag
                  color={
                    consentToText === "Yes" ? "green" : "red"

                  }
                  key={consentToText}
                >
                  {consentToText}
                </Tag>
              </>
            )}
          />
        </Table>
      </Spin>
      {/* } */}
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
        centered={true}
        // closeIcon={<CloseOutlined onClick={() => setIsSubmitting(false)} />}
        style={{ minHeight: "300px", fontSize: "1rem" }}
      >
        {modalComponent}
      </Modal>
    </div>
  );
}

export default AthenaDetails;
