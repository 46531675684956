import { memo } from 'react'
import Header from './Header'
import WeekDays from './WeekDays'
import Days from './Days'
import './Calendar.css'

const Calendar = ({ availableDays, date, selectedDay, setDate, setSelectedDay }) => (
  <div className='calendar'>
    <Header date={date} setDate={setDate} />
    <WeekDays />
    <Days
      availableDays={availableDays}
      date={date}
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
    />
  </div>
)

export default memo(Calendar)
