import React, { useState, useEffect } from "react";
import "../styles/Details.css";
import { ArrowRightOutlined, ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip, Spin, Tag, Modal, Card, Tabs, Dropdown } from "antd";
import { notify } from "../utils/notify";
import { getAWSErrorLogs, getCloudwatchLogGroups } from "../services/AWSErrorLogsService";
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
const { Column } = Table;

function AWSErrorLogs() {
    document.title = "LunaJoy Dashboard"
    const [data, setData] = useState([]);
    const [application, setApplication] = useState('');
    const [loading, setLoading] = useState(false);
    const [logGroups, setLogGroups] = useState([])
    const [timestamp, setTimestamp] = useState(new Date().getTime());

    useEffect(() => {
        const loadLogGroups = async () => {
            try {
                const logGroupsData = await getCloudwatchLogGroups()
                setLogGroups(logGroupsData);
                loadData(logGroupsData, timestamp)
            } catch (error) {
                console.error("Error loading log groups:", error);
            }
        };

        loadLogGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);


    async function loadData(logGroupsData, timestamp) {
        try {
            setLoading(true);
            const { startTime, endTime } = calculateStartAndEndTimes(timestamp, 8, 'subtract')

            let log;
            if (application) {
                const filteredData = logGroupsData.filter((group) => group.application === application
                )
                log = filteredData[0]
            } else {
                log = logGroupsData[0]
            }
            console.log(log)
            const errorLogData = await getAWSErrorLogs(log.id, startTime, endTime);
            // eslint-disable-next-line array-callback-return
            errorLogData.map((errorLog) => {
                errorLog.application = log.application
                errorLog.timestamp = new Date(errorLog.timestamp).toUTCString()
                errorLog.ingestionTime = new Date(errorLog.ingestionTime).toUTCString()
            })
            setData(errorLogData)
        } catch (e) {
            console.log(e)
            Modal.confirm({
                title: 'Error Message',
                content: 'Oops! Error in Fetching data',
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } },
                onOk: async () => {
                    console.log("Error in Fetching data");
                },
            });
        } finally {
            setLoading(false); // Reset loading once data fetching is complete
        }
    }


    function calculateStartAndEndTimes(timestamp, duration, action) {
        const time1 = new Date(parseInt(timestamp, 10)); // End time in milliseconds
        const time2 = new Date(time1);
        // data is retrieved for given duration
        if (action === 'subtract') {
            time2.setHours(time2.getHours() - duration); // Start time in milliseconds
            return { startTime: time2.getTime(), endTime: time1.getTime() }
        } else if (action === 'add') {
            time2.setHours(time2.getHours() + duration); // Start time in milliseconds
            return { startTime: time1.getTime(), endTime: time2.getTime() }
        }
        return time2
    }

    const onChange = (key) => {
        setApplication(key)
        console.log(key);
    };

    const handleLeftClick = () => {
        const { endTime } = calculateStartAndEndTimes(timestamp, 8, 'add')
        setTimestamp(endTime)
        loadData(logGroups, endTime)
    };

    const handleRightClick = () => {
        const { startTime } = calculateStartAndEndTimes(timestamp, 8, 'subtract')
        setTimestamp(startTime)
        loadData(logGroups, startTime)
    };

    const generateExcel = (data) => {
        const columnsToInclude = ['logStreamName', 'timestamp', 'message', 'ingestionTime', 'eventId'];

        // Filter the data to include only the specified columns
        const filteredData = data.map((item) => {
            // Create a new object with only the specified columns
            const filteredItem = {};
            columnsToInclude.forEach((column) => {
                filteredItem[column] = item[column];
            });
            return filteredItem;
        });

        const filetype =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        return new Blob([excelBuffer], { type: filetype });
    };

    const handleDownloadMuster = async (e) => {
        setLoading(true);


        try {
            let blobData, fileExtension;

            blobData = generateExcel(data);
            fileExtension = '.xlsx';
        
            //  save the pdf to user's local device
            saveAs(blobData, `Error_log_for_${application}` + fileExtension);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
            notify('Downloaded!', 'Please check your downloads!');
        }

    };


    return (
        <div id="dashboard-container">
            <div className='row styleFilterRow'>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ marginTop: 5 }}>
                        <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Logs</span>
                        {data && <Tag>
                            <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : data.length}</strong>
                        </Tag>}

                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className='dateStyling' style={{ marginRight: '10px' }}>Export excel</span>
                        
                            <DownloadOutlined style={{ marginRight: "10px", fontSize: "18px", marginTop: "1px", cursor: "pointer" }} onClick={handleDownloadMuster}/>
                        
                    </div>
                </div>
            </div>
            <Spin spinning={loading}>
                <div className='row styleFilterRow'>



                    <Tabs
                        onChange={onChange}
                        type="card"
                        size="100px"

                        items={logGroups.map((log) => {
                            return {
                                label: `${log.application}`,
                                key: log.application,

                            };
                        })}
                    />

                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        <div style={{ marginTop: 1 }}>
                            <Button>
                                <ArrowLeftOutlined
                                    onClick={handleLeftClick}
                                />
                            </Button>

                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button>
                                <ArrowRightOutlined
                                    onClick={handleRightClick}
                                />
                            </Button>
                        </div>
                    </div>
                </div>

                <Table
                    dataSource={data}
                    bordered={true}
                    pagination={true}
                    scroll={{
                        x: true
                    }}
                >

                    <Column
                        title="Log Stream Name"
                        dataIndex="logStreamName"
                        key="logStreamName"

                    />

                    <Column
                        title="Timestamp"
                        dataIndex="timestamp"
                        key="timestamp"
                    />
                    <Column
                        title="Message"
                        dataIndex="message"
                        key="message"

                    />

                    <Column
                        title="Ingestion Time"
                        dataIndex="ingestionTime"
                        key="ingestionTime"
                    />

                    <Column
                        title="Event ID"
                        dataIndex="eventId"
                        key="eventId"
                    />
                </Table>

            </Spin>
        </div >
    );
}


export default AWSErrorLogs;
