import React from 'react';
import '../styles/LoginPage.css';
import logo from '../assets/icon.svg';
import { BACKEND_URL } from '../utils/urls';
import GoogleButton from 'react-google-button';

const LoginPage = ({ onLogin }) => {

    const checkLoginStatus = async () => {
        if (localStorage.getItem('user')) {
            onLogin();
        }
    };

    const handleGoogleLogin = async () => {
        try {
            let timer = null;
            const googleLoginURL = `${BACKEND_URL}/api/auth/google`;
            const newWindow = window.open(
                googleLoginURL,
                "__blank",
                "width=500,height=600"
            );
            if (newWindow) {
                timer = setInterval(() => {
                    if (newWindow.closed) {
                        checkLoginStatus();
                        if (timer) clearInterval(timer);
                    }
                }, 200);
            }

        } catch (error) {
            console.error('Error during Google login:', error);
        }
    };

    return (
        <div className="login-container" >
            <div className="login-logo">
                <img src={logo} alt="Logo" />
            </div>

            <h3>LunaJoy</h3>
            <GoogleButton onClick={handleGoogleLogin} />

        </div>
    );
};

export default LoginPage;
