export const DEV_BACKEND_URL = 'https://api.portal-dev.hellolunajoy.com'
export const LOCAL_BACKEND_URL = 'http://localhost:6001'
export const PROD_BACKEND_URL = 'https://api.portal.hellolunajoy.com'

//booking url for insurance
export const DEV_BOOKING_URL = 'https://booking-dev.hellolunajoy.com'
export const LOCAL_BOOKING_URL = 'http://localhost:3001'
export const PROD_BOOKING_URL = 'https://booking.hellolunajoy.com/'

export const BACKEND_URL =
  window.location.hostname === 'portal.hellolunajoy.com'
    ? PROD_BACKEND_URL
    : window.location.hostname === 'portal-dev.hellolunajoy.com'
    ? DEV_BACKEND_URL
    : LOCAL_BACKEND_URL

export const BOOKING_APP_URL =
  window.location.hostname === 'portal.hellolunajoy.com'
    ? PROD_BOOKING_URL
    : window.location.hostname === 'portal-dev.hellolunajoy.com'
    ? DEV_BOOKING_URL
    : LOCAL_BOOKING_URL
export const DEV_FORMS_URL = "https://api.forms-dev.hellolunajoy.com/graphql"
export const LOCAL_FORMS_URL = "http://localhost:5000/graphql"
export const PROD_FORMS_URL = "https://api.forms.hellolunajoy.com/graphql"


export const FORMS_URL = window.location.hostname === "portal.hellolunajoy.com" ? PROD_FORMS_URL : window.location.hostname === "portal-dev.hellolunajoy.com" ? DEV_FORMS_URL : LOCAL_FORMS_URL   
