import './FormControl.css'

const FormControl = ({ children }) => {

  return (
    <div className='control'>
      {children}
    </div>
  )
}

export default FormControl
