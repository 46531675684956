import { DownOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Select } from 'antd'

const CustomSelectWrapper = styled(Select)`
  && {
    .ant-select-selector {      
      color: #263238;
      border-radius: 0.4rem;
      border: 0.05rem solid #c9c9c9;
      font-size: 0.9rem !important;
      align-items: center;
      padding-left: 1rem;
      background: white !important;

      &:hover {
        border: 0.05rem solid black !important;
      }
    }
  }
`
// Using Ant design to create a custom select for all selects
const CustomSelect = (props) => {
  const dropdownStyles = {
    backgroundColor: 'white',
    color: '#4f4f4f'
  }

  const CustomArrowIcon = styled(DownOutlined)`
  && {
    color: black;
    font-weight: 700;
  }
`

  return (
    <CustomSelectWrapper
      {...props}
      popupClassName="custom-select-dropdown"
      dropdownStyle={dropdownStyles}
      suffixIcon={<CustomArrowIcon />}
      disabled={props.disabled}
    />
  )
}

export default CustomSelect
