import React, { useEffect } from "react";
import { BACKEND_URL } from '../utils/urls';
import axios from 'axios';

export default function LoginSuccess() {
    const fetchUser = async () => {
        const response = await axios
            .get(`${BACKEND_URL}/api/auth/user`, { withCredentials: true })
            .catch((err) => {
                console.log("Not properly authenticated");
            });
        // console.log(response);
        if (response && response.data) {
            const user = JSON.stringify({
                name: response.data.name,
                id: response.data.id,
                email: response.data.email
            })
            localStorage.setItem('user', user);
        }
        window.close();
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return <div>Thanks for loggin in!</div>;
}
