import { memo } from 'react'
import { getDate, isSameMonth, parseISO } from 'date-fns'
import './Day.css'

const Day = ({ date, day, hasAvailableTime, isSelected, setSelectedDay }) => {
  const dayDate = parseISO(day)

  function handleButtonClick() {
    setSelectedDay(day)
  }

  return (
    <li>
      <button
        type="button"
        className={`${isSelected && 'selected'} ${hasAvailableTime ? 'available' : 'notAvailable'} ${!isSameMonth(date, dayDate) && 'absent'} day`}
        disabled={!hasAvailableTime}
        onClick={handleButtonClick}
      >
        {getDate(dayDate)}
      </button>
    </li>
  )
}

export default memo(Day)
