import React from "react";
import { Button, DatePicker, Form, Input, InputNumber, Spin } from "antd";
import { setErrorMap } from 'zod'
import { customErrorMap, getValidator } from "../../utils/validation";

const CreateNewPatientForm = ({ loading, form, handleCreate, handleCreateCancel }) => {
  setErrorMap(customErrorMap)

  const buttonStyle = {
    background: "#faad14",
    color: "black",
    float: "none",
    margin: 0,
    alignSelf: "center",
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} onFinish={handleCreate} layout="vertical" encType="multipart/form-data">
        <Form.Item
          name="firstname"
          label={(<strong>First Name<span style={{ color: 'red', fontWeight: "bold" }}>*</span></strong>)}
          rules={
            getValidator('firstName', 'Please enter a valid first name.')
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastname"
          label={(<strong>Last Name<span style={{ color: 'red', fontWeight: "bold" }}>*</span></strong>)}
          rules={
            getValidator('lastName', 'Please enter a valid last name.')
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="dob"
          label={(<strong>Date of Birth<span style={{ color: 'red', fontWeight: "bold" }}>*</span></strong>)}
          rules={getValidator('dob', 'Please enter a valid DOB.')}
        >
          <DatePicker
            format="MM/DD/YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="phone_no"
          label={(<strong>Mobile Number<span style={{ color: 'red', fontWeight: "bold" }}>*</span></strong>)}
          rules={getValidator('mobileNumber', 'Please enter a valid mobile number.')}
        >
          <InputNumber
            maxLength={10}
            addonBefore="+1"
            type="number"
            controls={false}
            pattern="[0-9]*"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label={(<strong>Email<span style={{ color: 'red', fontWeight: "bold" }}>*</span></strong>)}
          rules={getValidator('email', 'Please enter a valid email.')}
        >
          <Input />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <Form.Item style={{ marginRight: 16 }}>
            <Button onClick={handleCreateCancel}>Cancel</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={buttonStyle}>
              Create Patient
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default CreateNewPatientForm;
