import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from 'react-date-range';
import { Button, Table, Tooltip, Spin, Tag, Modal, Image, Card } from "antd";
import "../styles/Details.css";
import Preview from "../assets/view.png";
import refreshIcon from "../assets/refresh.svg";
import CalendarGrey from '../assets/Calendar Dark.svg'
import CalendarBlue from '../assets/Calendar Blue.svg'
import { formatDate, formatDateAndTimeInlocal, formateDateForAuditlog, dateFormatGeneric, formateDateForFax, formatDateInlocal } from "../utils/dateFormat";
import MonthlyFaxServices from "../services/MonthlyFaxService";
import axios from "axios";
import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import FilterComponent from "../components/FilterComponent";
// Import the main component

const { Column } = Table;

function MonthlyFaxReview() {
  document.title = "Fax Review"
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [data, setData] = useState([]);
  const [clinicNames, setClinicNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([])
  const [filters, setFilters] = useState({});

  const datePickerRef = useRef(null);
  const buttonRef = useRef(null);

  const [numPage, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const [retryClicked, setRetryClicked] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [faxFilters, setFaxFilters] = useState({});

  const filterCardRef = useRef(null);
  const filterButtonRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    console.log('pdfBlob', pdfBlob);
  }, [pdfBlob])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleTableChange = (pagination, filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    loadData()
  }, [faxFilters]);

  async function loadData() {
    try {
      setLoading(true);
      const logs = await MonthlyFaxServices.getMonhtlyFaxReview(faxFilters);
      setData(logs);
    } catch (e) {
      console.log(e)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {
          console.log("Error in Fetching data");
        },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
  }

  const handlePreviewDocument = async (record) => {
    try {
      setLoading(true);
      const fileBlob = await MonthlyFaxServices.getDocument(record);
      const windowUrl = window.URL.createObjectURL(fileBlob);
      console.log('windowUrl', windowUrl);
      setPdfBlob(windowUrl);
      setIsModalOpen(true);
      setModalTitle('Document Preview');
    } catch (error) {
      console.error('Error Preview the document:', error);
    } finally {
      setLoading(false);
    }
  };



  const handleGeneratedRetry = async (record) => {
    if (!retryClicked) {
      // Send a message when the button is clicked for the first time
      alert('Retry done!');
      setRetryClicked(true);
      try {
        await MonthlyFaxServices.handleFaxGeneratedRetry(record.id);

        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const filteredData = data.filter((item) => {
    return (!filtered.clinic || (item.clinic === filtered.clinic));
  });

  const handleCancel = () => {
    window.location.reload()
    setIsModalOpen(false);
  };

  const handleColumnClick = () => {
    setChecked(!isChecked);
  }

  const handleReview = async (record) => {
    if (!isChecked) {
      alert('Review done!');
      setChecked(true);
      try {

        await MonthlyFaxServices.handelIsReview(record.id);


      } catch (error) {
        console.error(error);
      }
      window.location.reload();
    }
  };

  const handleRefreshMonthlyReport = async (record) => {
    try {
      setLoading(true);
      const response = await MonthlyFaxServices.regenrateMonthlyFaxReport(record.clinic, record.type);
      if(response.status == "success") {
        Modal.confirm({
          title: 'Report Generated',
          content: 'Report regenerated successfully. Please review',
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } },
          onOk: async () => {
            loadData();
          },
        });
      }
    } catch(e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const setSearchField = (e) => {
    if(e.target.value) {
      setFaxFilters({...faxFilters, [e.target.name]: e.target.value});
    }else{
      const copyFilters = {...faxFilters};
      delete copyFilters[e.target.name];

      setFaxFilters(copyFilters);
    }
  }

  const handleFilter = () => {
    setIsFilterVisible(true);
  };

  const handleClose = () => {
    setIsFilterVisible(false);
  };

  return (
    <div id="dashboard-container">
      <div className='row styleFilterRow'>

          <div style={{ position: "relative", width: "100%" }}>
        <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'space-between' }}>
            <div>
              <div style={{ marginTop: 5 }}>
                <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Logs</span>
                <Tag>
                  <strong style={{ fontSize: 16 }}>{filteredData.length === 0 ? 0 : filteredData.length}</strong>
                </Tag>
                <Button style={{ fontsize: 16, padding: 5 }} onClick={() => navigate("/faxes")}>Fax Dashboard</Button>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span className="dateStyling" style={{ marginRight: "10px" }}>
                Filter
              </span>
              <div style={{ position: "relative" }}>
                <Tooltip title="Filter" >
                  <FilterOutlined
                    style={{ marginRight: "10px", fontSize: "18px", marginTop: "5px", cursor: "pointer" }}
                    ref={filterButtonRef}
                    onClick={handleFilter}
                  />
                </Tooltip>

                {isFilterVisible && (
                  <div
                    style={{ position: "absolute", top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}
                  >
                    <Card
                      title="Filter Options"
                      bordered={true}
                      extra={
                        <Button type="Close" onClick={handleClose}>
                          <CloseCircleOutlined />
                        </Button>
                      }
                    >
                      <FilterComponent
                        clinicNames={clinicNames}
                        from={"MonthlyFax"}
                        setSearchField={setSearchField}
                        filters={faxFilters}
                      />
                    </Card>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>



        <Spin spinning={loading}>
          <Table
            dataSource={filteredData}
            onChange={handleTableChange}
            bordered={true}

          >
            {!filtered.clinic && (
              <Column
                title="Clinic"
                dataIndex="clinic"
                key="clinic"


              />
            )}

            <Column

              title="Fax Type"
              dataIndex="type"
              key="channel"
            />

            <Column

              title={"Review Status"}
              dataIndex="isReviewed"
              key="isReviewed"
              onClick={handleColumnClick}
              render={(id, record) => (
                <>
                  <Tooltip title={record.isReviewed !== false ? "Reviewed" : "Mark as review"}>

                    <Button
                      disabled={record.isReviewed !== false}
                      onClick={() => {
                        handleReview(record);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {record.isReviewed !== false ? "Reviewed" : "Mark as review"}
                    </Button>
                  </Tooltip>
                </>
              )}
            />



            <Column
              title="Generated Date"
              dataIndex="createdAt"
              key="createdAt"

              width={130}
              render={(date) => (
                <>
                  <div>{date ? formatDate(date) : " "}</div>
                </>
              )}

            />
            <Column
              title="Preview"
              dataIndex="id"
              key="id"
              render={(id, record) => {
                if (record.document) {
                  return (
                    <>
                      <Tooltip title={"Preview"}><Image src={Preview} width={24} preview={false} onClick={() => handlePreviewDocument(record)}
                        style={{ cursor: "pointer" }} /></Tooltip>

                    </>
                  )
                } else {
                  return (
                    <>
                      <Tooltip title={"Retry"} key={id}>
                        <Button
                          onClick={() => {
                            handleGeneratedRetry(record);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Retry
                        </Button>
                      </Tooltip>
                    </>
                  )

                }
              }}
            />

            <Column
              title="Regenerate"
              dataIndex="regenerate"
              key="regenerate"
              render={(id, record) => {
                  return (
                    <>
                      <Tooltip title={"Regenerate"} key={"regenerate"}>
                        <img
                          src={refreshIcon}
                          onClick={() => {
                            handleRefreshMonthlyReport(record);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </>
                  )
              }}
            />
          </Table>

        </Spin>

        <Modal
          title={modalTitle}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          maskClosable={true}
          centered={true}
          width={'100%'}
          height={'100%'}
          style={{ width: '100%', height: '100%' }}
          bodyStyle={{ height: '100%', padding: 0, overflow: 'auto' }}
        >
          {pdfBlob && <object style={{ width: '100%', height: '95vh' }} data={pdfBlob}></object>}
        </Modal>




      </div >
      );
}

      export default MonthlyFaxReview;
