import React from 'react'
import { Button, Input, Spin, Table, Tag, Tooltip } from 'antd'
import { formatDateAndTimeInlocal, formatDateForApi, formatDateForGeneralInfo } from '../../utils/dateFormat'
import { viewAllAppointmentHistory, viewEmailAndSmsConsentDetails, viewHippaConsentDetails } from './ViewDetails'
import { BOOKING_APP_URL } from '../../utils/urls'
import IntakeService from '../../services/IntakeService'
import { notify } from '../../utils/notify'
import { RedoOutlined } from '@ant-design/icons'
const { Column } = Table

const IntakeTable = ({
  loading,
  data,
  currentPage,
  pageLimit,
  totalItems,
  handleTableChange,
  setIsModalOpen,
  setModalTitle,
  setIsPdfModal,
  setIsAppointmentModal,
  setModalComponent,
  editContent,
  setLoading,
  handleRefresh,
  handleComingSoon,
  bookFollowup,
  setIsInsuranceModal
}) => {
  const handleAddInsurance = (record) => {
    if (record.has_general_information) {
      const url = `${BOOKING_APP_URL}/handle-redirect?from=intake-fd&contact_id=${record.ghl_contact_id}`
      window.open(url, '_blank', 'noopener,noreferrer')
    } else {
      notify(`Please complete the general information and try again.`, '', 'error')
    }
  }

  const handleShareLink = async (record) => {
    setLoading(true)
    if (record.has_general_information) {
      const url = `${BOOKING_APP_URL}/handle-redirect?from=intake-patient&contact_id=${record.ghl_contact_id}`

      try {
        const success = await IntakeService.sendIntakeEmailAndSMS(record.ghl_contact_id, url)
        success
          ? notify(`Email and sms sent to ${record?.firstname} ${record?.lastname} successfully.`, '')
          : notify(`Failed to send email or sms to ${record?.firstname} ${record?.lastname}. Please try again.`, '', 'error')
      } catch (error) {
        console.error(error)
        notify(`Failed to send email or sms to ${record?.firstname} ${record?.lastname}. Please try again.`, '', 'error')
      }
    } else {
      notify(`Please complete the general information and try again.`, '', 'error')
    }
    setLoading(false)
  }

  const handlePrivatePayShareLink = async (record) => {
    setLoading(true)
    if (record.has_general_information) {
      const url = `${BOOKING_APP_URL}/handle-redirect?from=intake-patient&contact_id=${record.ghl_contact_id}&stage=consent&payment_method=self-pay`

      try {
        const success = await IntakeService.sendConsentCCIntakeEmailAndSMS(record.ghl_contact_id, url)
        success
          ? notify(`Email and sms sent to ${record?.firstname} ${record?.lastname} successfully.`, '')
          : notify(`Failed to send email or sms to ${record?.firstname} ${record?.lastname}. Please try again.`, '', 'error')
      } catch (error) {
        console.error(error)
        notify(`Failed to send email or sms to ${record?.firstname} ${record?.lastname}. Please try again.`, '', 'error')
      }
    } else {
      notify(`Please complete the general information and try again.`, '', 'error')
    }
    setLoading(false)
  }

  const handleConsentCCShareLink = async (record, stage) => {
    setLoading(true)
    if (record.has_general_information) {
      const paymentInfo = {}
      record?.customField?.forEach((elem) => {
        switch (elem.id) {
          case '4q6TR9zHenmq4zKJmKOl':
            paymentInfo.paymentMethod = elem.value
            break
          default:
            break
        }
      })

      let url = ''
      if (paymentInfo?.paymentMethod === 'Self-Pay') {
        url = `${BOOKING_APP_URL}/handle-redirect?from=intake-patient&contact_id=${record.ghl_contact_id}&stage=${stage}&payment_method=self-pay`
      }

      url = `${BOOKING_APP_URL}/handle-redirect?from=intake-patient&contact_id=${record.ghl_contact_id}&stage=${stage}&payment_method=insurance`

      try {
        const success = await IntakeService.sendConsentCCIntakeEmailAndSMS(record.ghl_contact_id, url)
        success
          ? notify(`Email and sms sent to ${record?.firstname} ${record?.lastname} successfully.`, '')
          : notify(`Failed to send email or sms to ${record?.firstname} ${record?.lastname}. Please try again.`, '', 'error')
      } catch (error) {
        console.error(error)
        notify(`Failed to send email or sms to ${record?.firstname} ${record?.lastname}. Please try again.`, '', 'error')
      }
    } else {
      notify(`Please complete the general information and try again.`, '', 'error')
    }
    setLoading(false)
  }

  return (
    <Spin spinning={loading}>
      <Table
        dataSource={data}
        pagination={{
          current: currentPage,
          pageSize: pageLimit,
          total: totalItems
        }}
        bordered={true}
        onChange={handleTableChange}
        scroll={{ y: '64vh' }}
      >
        <Column
          title="Patient Name"
          dataIndex="name"
          key="name"
          render={(text, record) => (
            <div>
              <strong>{record.patientName}</strong>
              <div>{record.email}</div>
              <div>{record.phone_no}</div>
            </div>
          )}
        />
        <Column
          title="Date"
          dataIndex="createdAt"
          key="createdAt"
          render={(date) => (
            <>
              <div>{date ? formatDateAndTimeInlocal(date) : ' '}</div>
            </>
          )}
        />
        <Column title="Channel" dataIndex="source" key="source" />
        <Column
          title="Clinic"
          dataIndex="customField"
          key="clinic"
          render={(text, record) => {
            return record?.clinic_name
          }}
        />
        <Column
          title="General Information"
          dataIndex="status"
          key="generalInfoStatus"
          width={120}
          render={(text, record) => {
            const { firstname, lastname, email, phone_no, dob } = record

            const showModal = () => {
              console.log({ record })
              setIsModalOpen(true)
              setModalTitle('Patient Information')
              setIsPdfModal(false)
              setIsAppointmentModal(false)
              setIsInsuranceModal(false)

              setModalComponent(
                <>
                  <strong>First Name</strong>
                  <Input value={firstname} style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />

                  <strong>Last Name</strong>
                  <Input value={lastname} style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />

                  <strong>Date of Birth</strong>
                  <Input value={dob ? formatDateForGeneralInfo(dob) : ' '} style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />

                  <strong>Phone Number</strong>
                  <Input value={phone_no} style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />

                  <strong>Email</strong>
                  <Input value={email} style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
                </>
              )
            }

            return (
              <>
                {firstname && lastname && dob && phone_no && email ? (
                  <>
                    <div style={{ marginBottom: '0.5rem' }}>
                      <Tag color="success">Complete</Tag>
                    </div>
                    <Tooltip title="View General Information">
                      <Button size="small" onClick={showModal} style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}>
                        View
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <div style={{ marginBottom: '0.5rem' }}>
                      <Tag color="warning">Not Complete</Tag>
                    </div>
                    <Tooltip title="Update General Information">
                      <Button size="small" onClick={() => editContent(record)}>
                        Update
                      </Button>
                    </Tooltip>
                  </>
                )}
              </>
            )
          }}
        />
        <Column
          title="Insurance Details"
          dataIndex="customField"
          key="insuranceDetails"
          width={200}
          render={(text, record) => {
            const { firstname, lastname, email, phone_no, dob } = record

            const showModal = () => {
              setIsModalOpen(true)
              setModalTitle('Insurance Details')
              setIsPdfModal(false)
              setIsAppointmentModal(false)
              setIsInsuranceModal(true)

              const datasourceJson = []

              // Check if primary insurance exists
              if (record.has_primary_insurance) {
                datasourceJson.push({
                  insurance_type: 'Primary',
                  insurance_name: '--',
                  insurance_holder_name: '--',
                  insurance_holder_date_of_birth: '--',
                  member_id: '--',
                  group_id: '--',
                  insurance_check: '--',
                  copay: '--',
                  coverage: '--',
                  start_date: '--',
                  end_date: '--',
                  carrier: '--'
                })

                // Populate primary insurance data
                record.customField.forEach((elem) => {
                  switch (elem.id) {
                    case 'RQibNBsms3u2kVXYfJBp': // for Insurance/Payer
                      datasourceJson[0].insurance_name = elem.value
                      break
                    case 'tEmWukDgOZT0EqG1PqMI': // for Insurance Plan Name
                      datasourceJson[0].insurance_name = elem.value
                      break
                    case 'SBAXh6BGH3HZtCfFIB3v':
                      datasourceJson[0].insurance_holder_name = elem.value
                      break
                    case 'ZTiFBMUw4BLfjSc5KXLm':
                      datasourceJson[0].insurance_holder_date_of_birth = formatDateForApi(elem.value)
                      break
                    case 'XgiKtTfkQWg2xkDdVCWo':
                      datasourceJson[0].member_id = elem.value
                      break
                    case 'uicsr13pXKigNqF8dKbd': // for old memmber id
                      datasourceJson[0].member_id = elem.value
                      break
                    case 'u9UeWToLzXtREXSIJS96':
                      datasourceJson[0].group_id = elem.value
                      break
                    case 'S5EaMCYLconFWjHTkvsn':
                      datasourceJson[0].insurance_check = elem.value
                      break
                    case 'p9wIIEdq0ExLo0L72bj7':
                      datasourceJson[0].copay = elem.value
                      break
                    case 'PCgt1E9JfuPmHncb72ee':
                      datasourceJson[0].coverage = elem.value
                      break
                    case 'LqJlABqKnVUR5gbWTGjt':
                      datasourceJson[0].start_date = elem.value
                      break
                    case 'DZl7ODk9kA9wj0YNX164':
                      datasourceJson[0].end_date = elem.value
                      break
                    case 'WefQikS8X5lcEcBdVpEV':
                      datasourceJson[0].carrier = elem.value
                      break
                    default:
                      break
                  }
                })
              }

              // Check if secondary insurance exists
              if (record.has_secondary_insurance) {
                datasourceJson.push({
                  insurance_type: 'Secondary',
                  insurance_name: '--',
                  insurance_holder_name: '--',
                  insurance_holder_date_of_birth: '--',
                  member_id: '--',
                  group_id: '--',
                  insurance_check: '--',
                  copay: '--',
                  coverage: '--',
                  start_date: '--',
                  end_date: '--',
                  carrier: '--'
                })

                // Populate secondary insurance data
                record.customField.forEach((elem) => {
                  switch (elem.id) {
                    case '3IccNfLGEWDGx9C2BgZ8':
                      datasourceJson[datasourceJson.length - 1].insurance_name = elem.value
                      break
                    case 'eP5ATXCVrppbe2s7e5ZO':
                      datasourceJson[datasourceJson.length - 1].insurance_holder_name = elem.value
                      break
                    case 'PZ9mXY9odXt1mPCahYLx':
                      datasourceJson[datasourceJson.length - 1].insurance_holder_date_of_birth = formatDateForApi(elem.value)
                      break
                    case '9uqXDsMltvIIdb7QIbpZ':
                      datasourceJson[datasourceJson.length - 1].member_id = elem.value
                      break
                    case 'sXx7b6SMQRbEY63uArml':
                      datasourceJson[datasourceJson.length - 1].group_id = elem.value
                      break
                    case 'xRKyhZaWvspjuXYb1bsz':
                      datasourceJson[datasourceJson.length - 1].insurance_check = elem.value
                      break
                    case 'VSWIGUf8wokSptcxBnHR':
                      datasourceJson[datasourceJson.length - 1].copay = elem.value
                      break
                    case 'XQ7Tb2dO7OWfJmBcplwl':
                      datasourceJson[datasourceJson.length - 1].coverage = elem.value
                      break
                    case 'qAz3tsG4iY3wcUoER2ME':
                      datasourceJson[datasourceJson.length - 1].start_date = elem.value
                      break
                    case 'RRx5uMISCr2fUrT1GQFB':
                      datasourceJson[datasourceJson.length - 1].end_date = elem.value
                      break
                    case 'VPVwq9KQ2GmuYcMbSJck':
                      datasourceJson[datasourceJson.length - 1].carrier = elem.value
                      break
                    default:
                      break
                  }
                })
              }

              setModalComponent(
                <>
                  <Table dataSource={datasourceJson} pagination={false} className="custom-apptHistory-table" tableLayout="fixed">
                    <Column title="Insurance Type" dataIndex="insurance_type" key="insurance_type" />
                    <Column title="Insurance/Payer" dataIndex="insurance_name" key="insurance_name" />
                    <Column title="Insurance Holder Name" dataIndex="insurance_holder_name" key="insurance_holder_name" />
                    <Column title="Insurance Holder Date of Birth" dataIndex="insurance_holder_date_of_birth" key="insurance_holder_date_of_birth" />
                    <Column title="Member ID" dataIndex="member_id" key="member_id" />
                    <Column title="Insurance Status" dataIndex="insurance_check" key="insurance_check" />
                    <Column title="Copay" dataIndex="copay" key="copay" />
                    {/* <Column title="Coverage" dataIndex="coverage" key="coverage" /> */}
                    <Column title="Start Date" dataIndex="start_date" key="start_date" />
                    <Column title="End Date" dataIndex="end_date" key="end_date" />
                  </Table>
                </>
              )
            }

            const paymentInfo = {}
            record?.customField?.forEach((elem) => {
              switch (elem.id) {
                case '4q6TR9zHenmq4zKJmKOl':
                  paymentInfo.paymentMethod = elem.value
                  break
                default:
                  break
              }
            })

            if (paymentInfo?.paymentMethod === 'Self-Pay') {
              return <>Not required due to Self-Pay</>
            }

            return (
              <span>
                {record?.has_primary_insurance || record?.has_secondary_insurance ? (
                  <>
                    <div style={{ marginBottom: '0.5rem' }}>
                      <Tag color="success">Available</Tag>
                    </div>
                    <Tooltip title="View Insurance Details">
                      <Button size="small" onClick={showModal} style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}>
                        View
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <div style={{ marginBottom: '0.5rem' }}>
                      <Tag color="volcano">Not Available</Tag>
                    </div>
                    {firstname && lastname && dob && phone_no && email && (
                      <>
                        <Tooltip title="Add Insurance Details">
                          <Button size="small" onClick={() => handleAddInsurance(record)} style={{ marginBottom: '0.5rem' }}>
                            Add Insurance
                          </Button>
                        </Tooltip>
                        <Tooltip title="Share link with patient to add insurance, credit card details, and sign the consent">
                          <Button size="small" onClick={() => handleShareLink(record)} style={{ marginBottom: '0.5rem' }}>
                            Share Link - Insurance
                          </Button>
                        </Tooltip>
                        <Tooltip title="Share link with patient to add credit card details, and sign the consent">
                          <Button size="small" onClick={() => handlePrivatePayShareLink(record)}>
                            Share Link - Private-Pay
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </span>
            )
          }}
        />
        <Column
          title="Consent"
          dataIndex="customField"
          key="consentStatus"
          width={140}
          render={(customField, record) => {
            if (record?.has_hipaa_consent_signed && record?.has_email_and_sms_consent_signed) {
              return (
                <>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <Tag color="success">Available</Tag>
                  </div>
                  <Tooltip title="View HIPAA Consent">
                    <Button
                      size="small"
                      preview="false"
                      onClick={() =>
                        viewHippaConsentDetails(
                          record,
                          setIsModalOpen,
                          setModalTitle,
                          setIsPdfModal,
                          setLoading,
                          setModalComponent,
                          setIsAppointmentModal,
                          setIsInsuranceModal
                        )
                      }
                      style={{ marginBottom: '0.5rem' }}
                    >
                      HIPAA
                    </Button>
                  </Tooltip>
                  <Tooltip title="View Email & SMS Consent">
                    <Button
                      size="small"
                      preview="false"
                      onClick={() =>
                        viewEmailAndSmsConsentDetails(
                          record,
                          setIsModalOpen,
                          setModalTitle,
                          setIsPdfModal,
                          setLoading,
                          setModalComponent,
                          setIsAppointmentModal,
                          setIsInsuranceModal
                        )
                      }
                    >
                      Email & SMS
                    </Button>
                  </Tooltip>
                </>
              )
            }
            if (record?.has_hipaa_consent_signed) {
              // return "HIPAA Consent";
              return (
                <>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <Tag color="success">Available</Tag>
                  </div>
                  <Tooltip title="View HIPAA Consent">
                    <Button
                      size="small"
                      preview="false"
                      onClick={() =>
                        viewHippaConsentDetails(
                          record,
                          setIsModalOpen,
                          setModalTitle,
                          setIsPdfModal,
                          setLoading,
                          setModalComponent,
                          setIsAppointmentModal,
                          setIsInsuranceModal
                        )
                      }
                      style={{ marginBottom: '0.5rem' }}
                    >
                      HIPAA
                    </Button>
                  </Tooltip>
                </>
              )
            }
            if (record?.has_email_and_sms_consent_signed) {
              return (
                <>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <Tag color="success">Available</Tag>
                  </div>
                  <Tooltip title="View Email & SMS Consent">
                    <Button
                      size="small"
                      preview="false"
                      onClick={() =>
                        viewEmailAndSmsConsentDetails(
                          record,
                          setIsModalOpen,
                          setModalTitle,
                          setIsPdfModal,
                          setLoading,
                          setModalComponent,
                          setIsAppointmentModal,
                          setIsInsuranceModal
                        )
                      }
                    >
                      Email & SMS
                    </Button>
                  </Tooltip>
                </>
              )
            }
            if (!record?.has_hipaa_consent_signed || !record?.has_email_and_sms_consent_signed) {
              const paymentInfo = {}
              record?.customField?.forEach((elem) => {
                switch (elem.id) {
                  case '4q6TR9zHenmq4zKJmKOl':
                    paymentInfo.paymentMethod = elem.value
                    break
                  default:
                    break
                }
              })

              if (record.has_general_information && (paymentInfo?.paymentMethod === 'Self-Pay' || paymentInfo?.paymentMethod === 'Insurance')) {
                return (
                  <>
                    <Tag color="volcano">No Consent</Tag>
                    <Tooltip title="Share link with patient to sign the consent">
                      <Button size="small" onClick={() => handleConsentCCShareLink(record, 'consent')} style={{ marginTop: '0.5rem' }}>
                        Share Link
                      </Button>
                    </Tooltip>
                  </>
                )
              }
              return <Tag color="volcano">Not Available</Tag>
            }
          }}
        />
        <Column
          title="Credit Card Information"
          dataIndex="customField"
          key="creditCardInfo"
          width={160}
          render={(customField, record) => {
            if (record?.has_credit_card) {
              return (
                <div style={{ marginBottom: '0.5rem' }}>
                  <Tag color="success">Available</Tag>
                </div>
              )
            } else {
              // custom field {"id": "S5EaMCYLconFWjHTkvsn", "value": "Medicaid Valid"}
              if (record?.customField?.find((elem) => elem.value === 'Medicaid Valid')) {
                return <>Not required due to Medicaid</>
              } else if (record?.customField?.find((elem) => elem.value === 'Sunshine Health') && record?.customField?.find((elem) => elem.value === 'Non-Medicaid Valid')) {
                return <>Not required due to Sunshine Health</>
              } else if (record?.customField?.find((elem) => elem.value === 'Caresource') && record?.customField?.find((elem) => elem.value === 'Non-Medicaid Valid')) {
                return <>Not required due to Caresource</>
              }

              const paymentInfo = {}
              record?.customField?.forEach((elem) => {
                switch (elem.id) {
                  case '4q6TR9zHenmq4zKJmKOl':
                    paymentInfo.paymentMethod = elem.value
                    break
                  default:
                    break
                }
              })

              if (record.has_general_information && (paymentInfo?.paymentMethod === 'Self-Pay' || paymentInfo?.paymentMethod === 'Insurance')) {
                return (
                  <>
                    <Tag color="volcano">Not Available</Tag>
                    <Tooltip title="Share link with patient to provide credit card details">
                      <Button size="small" onClick={() => handleConsentCCShareLink(record, 'credit-card')} style={{ marginTop: '0.5rem' }}>
                        Share Link
                      </Button>
                    </Tooltip>
                  </>
                )
              }
              return <Tag color="volcano">Not Available</Tag>
            }
          }}
        />
        <Column
          title="Booking"
          dataIndex="tags"
          key="bookingStatus"
          width={160}
          render={(tags, record) => {
            const { has_general_information, has_primary_insurance, has_credit_card, has_hipaa_consent_signed, has_email_and_sms_consent_signed } =
              record

            if (record?.has_booking) {
              return (
                <>
                  <div style={{ marginBottom: '0.5rem' }}>
                    <Tag color="success">Done</Tag>
                  </div>
                  <Tooltip title="View Appointment Details">
                    <Button
                      size="small"
                      preview="false"
                      onClick={() =>
                        viewAllAppointmentHistory(
                          record,
                          setIsModalOpen,
                          setModalTitle,
                          setIsPdfModal,
                          setLoading,
                          setModalComponent,
                          setIsAppointmentModal,
                          setIsInsuranceModal
                        )
                      }
                    >
                      View Appointment
                    </Button>
                  </Tooltip>
                  <Tooltip title="Book follow-up appointment">
                    <Button size="small" onClick={() => bookFollowup(record)} style={{ marginTop: '0.5rem' }}>
                      Book Follow-up
                    </Button>
                  </Tooltip>
                </>
              )
            } else {
              if (
                has_general_information &&
                has_primary_insurance &&
                has_credit_card &&
                has_hipaa_consent_signed &&
                has_email_and_sms_consent_signed
              ) {
                return (
                  <>
                    <Tag color="volcano">Not Done</Tag>
                    <Tooltip title="Book Appointment">
                      <Button size="small" onClick={handleComingSoon} style={{ marginTop: '0.5rem' }}>
                        Book Appointment
                      </Button>
                    </Tooltip>
                  </>
                )
              }
              return <Tag color="volcano">Not Done</Tag>
            }
          }}
        />
        <Column
          title="Action"
          dataIndex=""
          key=""
          width={80}
          render={(tags, record) => (
            <>
              <Tooltip title="Refresh">
                <Button size="small" preview="false" icon={<RedoOutlined />} onClick={() => handleRefresh(record)} />
              </Tooltip>
            </>
          )}
        />
      </Table>
    </Spin>
  )
}

export default IntakeTable
