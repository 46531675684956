import { memo } from 'react'
import { addDays, format, startOfWeek } from 'date-fns'
import './WeekDays.css'

const monday = startOfWeek(new Date(), { weekStartsOn: 1 })
const weekDays = Array.from({ length: 7 }, (_, index) => format(addDays(monday, index), 'E'))

const WeekDays = () => (
  <ul className='days'>
    {weekDays.map((weekDay) => (
      <li key={weekDay}>
        {weekDay}
      </li>
    ))}
  </ul>
)

export default memo(WeekDays)
