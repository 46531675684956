import axios from 'axios';
import { json } from 'react-router-dom';
import { FORMS_URL } from '../utils/urls';
import { BACKEND_URL } from '../utils/urls';

export default class FormsSubmissionServices {

    async getFormsDetails() {
        let headersList = {
            "authority": "api.space.hellolunajoy.com",
            "accept": "application/graphql+json, application/json",
            "accept-language": "en-US,en;q=0.9,fr;q=0.8",
            "content-type": "application/json",
            "cookie": "_ga=GA1.1.1257599107.1677599362; _pin_unauth=dWlkPU16ZGhNelF6TWpVdE1qTTJZaTAwWVRRMkxUazVZVEV0TVdFelpUUmhOelUyTldWaQ; _hjSessionUser_2855504=eyJpZCI6ImZhNzBkNTRmLWYyOTAtNTkzOC1hNTVjLTJhODI0MTg4MzQ4ZCIsImNyZWF0ZWQiOjE2Nzc1OTkzNjIxNTksImV4aXN0aW5nIjp0cnVlfQ==; _fbp=fb.1.1684958927616.878471502; _gcl_au=1.1.695130156.1687767127; accessToken=c359dbd1-1b80-4e64-9ad3-6d7cf1aa4d7d; _derived_epik=dj0yJnU9MmJaQnVneFZmTkNyRGJIV3BManphMU9MVzUtRUoyOEQmbj1NMVFGMUlrekFRSWZ0TlIwSzd2WEZBJm09MSZ0PUFBQUFBR1RBemFFJnJtPTEmcnQ9QUFBQUFHVEF6YUUmc3A9Mg; _ga_26JXKRKYJ9=GS1.1.1690357150.11.0.1690357161.49.0.0; _ga_6ZEYLCN6RE=GS1.1.1690357151.110.0.1690357162.49.0.0; _ga_1X0XQRMB4F=GS1.1.1690357151.109.0.1690357162.0.0.0",
            "origin": "https://space.hellolunajoy.com",
            "referer": "https://space.hellolunajoy.com/",
            "user-agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36"
        }

        let bodyContent = JSON.stringify({ "query": "query ObGynFormSubmissions($clinic: String) {  ObGynFormSubmissions(clinic: $clinic) {    id    clinic    firstName    lastName    email    phone    insurances    clinician    contactMethod    createdAt    babyDay    epdsScore    phq9Score    gad7Score    gdsScore   phq9Answers   gad7Answers    epdsAnswers    gdsAnswers  anxietyAnswers  pregnancyStatus dob __typename  }}", "operationName": "ObGynFormSubmissions", "variables": {} });

        let reqOptions = {
            url: `${FORMS_URL}`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
        }

        try {
            let response = await axios.request(reqOptions);

            if (response.status === 200 && response.data) {
                const resData = await response.data;
                return resData;

            } else {
                throw json(
                    { message: 'Could not fetch events.' },
                    {
                        status: 500,
                    }
                );
            }
        } catch {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }

    }


    async getFormsDetailsWithFilter(input) {
        let bodyContent = {
            "query": "query FormSubmissionsCountByFilters($input: FormSubmissionsByFiltersInput) { FormSubmissionsCountByFilters (input: $input) { currentPage data {clinic firstName lastName email phone dob assessmentCount assessmentHistory {id clinic firstName lastName email phone insurances dob clinician contactMethod createdAt babyDay epdsScore phq9Score gad7Score epdsAnswers anxietyAnswers phq9Answers gad7Answers pregnancyStatus gdsScore gdsAnswers type __typename} __typename} totalRecords }}",
            "variables": {
                "input": input
            }
        }

        let reqOptions = {
            url: `${FORMS_URL}`,
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                vary: 'Origin'
            },
            data: bodyContent,
        }
        try {
            let response = await axios.request(reqOptions);

            if (response.status === 200 && response.data) {
                const resData = await response.data;
                return resData;

            } else {
                throw json(
                    { message: 'Could not fetch events.' },
                    {
                        status: 500,
                    }
                );
            }
        } catch {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async getFormsTempDetailsWithFilter(input) {
        let bodyContent = {
            "query": "query FormTempSubmissionsCountByFilters($input: FormSubmissionsByFiltersInput) { FormTempSubmissionsCountByFilters (input: $input) { currentPage data {id firstName lastName email phone dob mismatchReason __typename} totalRecords }}",
            "variables": {
                "input": input
            }
        }

        let reqOptions = {
            url: `${FORMS_URL}`,
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                vary: 'Origin'
            },
            data: bodyContent,
        }
        try {
            let response = await axios.request(reqOptions);

            if (response.status === 200 && response.data) {
                const resData = await response.data;
                return resData;

            } else {
                throw json(
                    { message: 'Could not fetch events.' },
                    {
                        status: 500,
                    }
                );
            }
        } catch {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async reconcileFormTempSubmissions(input) {
        const bodyContent = {
            query: `mutation reconcileFormTempSubmissions($input: reconcileFormTempSubmissionsInput!) {
                        reconcileFormTempSubmissions(input: $input)
                    }`,
            variables: {
                input: input
            }
        };

        const reqOptions = {
            url: `${FORMS_URL}`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json',  // Ensure Content-Type is set correctly
                'Access-Control-Allow-Origin': '*',
                vary: 'Origin'
            },
            data: bodyContent,
        };

        try {
            const response = await axios.request(reqOptions);

            if (response.status === 200 && response.data) {
                return response.data;
            } else {
                throw new Error('Could not reconcile assessment.');
            }
        } catch (error) {
            throw new Error('Could not reconcile assessment.');
        }
    }



    async getForms() {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/assessments`)
            if (response.status === 200 && response?.data) {
                const resData = await response.data;
                return resData;
            } else {
                throw json(
                    { message: 'Could not fetch events.' },
                    {
                        status: 500,
                    }
                );
            }
        } catch (e) {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }

    }

    async getOneForm(id) {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/assessments/${id}`)
            if (response.status === 200 && response?.data) {
                const resData = await response.data;
                return resData;
            } else {
                throw json(
                    { message: 'Could not fetch events.' },
                    {
                        status: 500,
                    }
                );
            }
        } catch (e) {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }

    }
}



export const { getForms, getOneForm, getFormsDetails, getFormsDetailsWithFilter, getFormsTempDetailsWithFilter, reconcileFormTempSubmissions } = new FormsSubmissionServices();