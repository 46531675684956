import React, { useState } from "react";
import { Card, Col, Row, Button, Modal, Input, Select, Spin } from "antd";
import states from "../../assets/data/states";
import { addZipCode } from "../../services/UtilService";
const { Option } = Select;

function AMDzipCode() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [loading, setLoading] = useState(false);

    const [zipCodeStatus, setZipCodeStatus] = useState("");
    const [cityStatus, setCityStatus] = useState("");
    const [stateStatus, setStateStatus] = useState("");

    const handleSubmit = async () => {
        // Perform validation before closing the modal
        let isValid = true;

        // Zip code validation
        if (!/^\d+$/.test(zipCode) || zipCode < 0 || zipCode > 99999) {
            setZipCodeStatus("error");
            isValid = false;
        } else {
            setZipCodeStatus("");
        }

        // City validation
        if (!/^[A-Za-z\s]+$/.test(city) || city.length < 3) {
            setCityStatus("error");
            isValid = false;
        } else {
            setCityStatus("");
        }

        // State validation
        if (!state) {
            setStateStatus("error");
            isValid = false;
        } else {
            setStateStatus("");
        }

        if (isValid) {
            setLoading(true);
            setIsModalOpen(false);
            const response = await addZipCode({ state, zipCode, city })
            console.log(response, 'zip res')
            if (response?.status === 'exists') {
                Modal.warn({
                    title: 'Zip code exists',
                    content: 'The zip code provided already exists. Please try another one!',
                    okText: 'OK',
                    centered: true
                })
            }
            else if (response?.status === 'success') {
                Modal.success({
                    title: 'Zip code added!',
                    okText: 'OK',
                    centered: true
                })
            }
            else if (response?.status === 'failed') {
                Modal.error({
                    title: 'Failed!',
                    content: 'An error occured while trying to add the zip code. Please try again!',
                    okText: 'OK',
                    centered: true
                })
            }
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Spin spinning={loading}>
            <div>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card title="AMD Zip Code" bordered={false} style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                            <Button type="primary" onClick={() => {
                                setIsModalOpen(true)
                                setCity('')
                                setZipCode('')
                            }}>
                                Add
                            </Button>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    title={modalTitle}
                    visible={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    maskClosable={true}
                    centered={true}
                    style={{ minHeight: "300px", fontSize: "1rem" }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', rowGap: '1rem' }}>
                        <Input
                            status={zipCodeStatus}
                            placeholder="Zip Code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            style={{ width: '300px' }}
                            type='number'
                            max={99999} // Set the maximum allowed value
                            min={0}     // Set the minimum allowed value
                        />
                        <Input
                            status={cityStatus}
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            style={{ width: '300px' }}
                        />
                        <Select
                            placeholder='State'
                            style={{ width: '300px' }}
                            status={stateStatus}
                            value={state || states[0].value} // Set the default value to the first state
                            onChange={(value) => setState(value)}
                        >
                            {states.map((state) => (
                                <Option key={state.value} value={state.value}>
                                    {state.text}
                                </Option>
                            ))}
                        </Select>
                        <Button type="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </Modal>
            </div>
        </Spin>
    );
}

export default AMDzipCode