import { Modal } from "antd";
import React from "react";

const EditModal = ({ isEditModalOpen, editModalContent, title }) => {
  return (
    <Modal
      styles={{ header: { textAlign: "center" } }}
      title={title}
      open={isEditModalOpen}
      closeIcon={false}
      centered={true}
      footer={null}
    >
      {editModalContent}
    </Modal>
  );
};

export default EditModal;
