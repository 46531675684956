import { memo } from 'react'
import Time from './Time'
import './AvailableTime.css'

function isSelectedTime(time, selectedTime) {
  return Boolean(selectedTime && time === selectedTime)
}

const AvailableTime = ({ availableTime, selectedTime, setSelectedTime }) => (
  availableTime?.length
    ? (
      <ul className='availableTime'>
        {availableTime.map((time) => (
          <Time
            key={time}
            time={time}
            isSelected={isSelectedTime(time, selectedTime)}
            setSelectedTime={setSelectedTime}
          />
        ))}
      </ul>
    )
    : null
)

export default memo(AvailableTime)
