export function categorizeAppointment(appointmentDescription) {
    if (appointmentDescription.includes("THER INDIV F/U") || appointmentDescription.includes("THER INDIV NEW")) {
        return "Therapy";
    }

    if (appointmentDescription.includes("THER FAMILY NEW") || appointmentDescription.includes("THER FAMILY F/U")) {
        return "Therapy Family";
    }

    if (appointmentDescription.includes("THER COUPLE NEW") || appointmentDescription.includes("THER COUPLE F/U")) {
        return "Therapy Couple";
    }

    if (appointmentDescription.includes("MED NEW") || appointmentDescription.includes("MED F/U")) {
        return "Medication";
    }

    if (appointmentDescription.includes("INMHP INDV F/U") || appointmentDescription.includes("INMHP INDV NEW")) {
        return "INMHP";
    }

    if (appointmentDescription.includes("COACH GROUP")) {
        return "Coach Group";
    }

    if (appointmentDescription.includes("COACH PEER GROUP")) {
        return "Coach Peer Group";
    }

    if (appointmentDescription.includes("COACH INDIV")) {
        return "Coach";
    }
    if (appointmentDescription.includes("THER GROUP")) {
        return "Therapy Group"
    }

    if (appointmentDescription.includes("MD/NP/PA INTAKE") || appointmentDescription.includes("MD/NP/PA F/U")) {
        return "MD/NP/PA";
    }
}