export function getRandomColor() {
    // Generate a random color code in hexadecimal format
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getInitials(name) {
    const nameParts = name.split(' ');
    let initials = '';

    const index = nameParts.length >= 2 ? 2 : 1

    for (let i = 0; i < index; i++) {
        initials += nameParts[i].charAt(0).toUpperCase();
    }

    return initials;
}