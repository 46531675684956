import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from "../utils/urls"
import { formatDateForApi } from '../utils/dateFormat';

export default class ReferralService {
    async getReferralDetails(startDate, endDate, pageNo, pageSize, searchText) {
        console.log(BACKEND_URL)
        const url = `${BACKEND_URL}/api/referrals`;
        console.log(startDate)
        const config = {
            headers: { 'Content-Type': 'application/json' },
            params: {
                fromDate: formatDateForApi(startDate),
                toDate: formatDateForApi(endDate),
                pageNo: pageNo,
                limit: pageSize,
                searchText: searchText
            }
        };
        const response = await axios.get(url, config)
        if (response.status === 200 && response?.data) {
            const resData = await response.data;

            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async getReferralDetailsOfClinic(id) {
        const response = await axios.get(`${BACKEND_URL}/api/referrals?carePartnerId=${id}`)
        if (response.status === 200 && response?.data) {
            const resData = await response.data;

            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async getReferralDetailsOfPatient(id) {
        const response = await axios.get(`${BACKEND_URL}/api/referrals?appointmentId=${id}`)
        if (response.status === 200 && response?.data) {
            const resData = await response.data;

            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

}

export const { getReferralDetails, getReferralDetailsOfClinic, getReferralDetailsOfPatient } = new ReferralService();