import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../styles/Details.css";
import { DateRangePicker } from 'react-date-range';
import CalendarGrey from '../assets/Calendar Dark.svg'
import CalendarBlue from '../assets/Calendar Blue.svg'
import { FilterOutlined, CloseCircleOutlined, FilterFilled } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Space, Input, Tooltip, DatePicker, Spin, Card } from "antd";
import moment from "moment";
import { getReferralDetails, getReferralDetailsOfClinic, getReferralDetailsOfPatient } from "../services/ReferralService";
import { formatDate, formatDateAndTimeInlocal } from "../utils/dateFormat";
import FilterComponent from "../components/FilterComponent";

const { Column } = Table;
const { Search } = Input;
const { RangePicker } = DatePicker;

function ReferralDetails() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const carePartnerId = queryParams.get('carePartner');
    const appointmentId = queryParams.get('patient');

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const [searchField, setSearchField] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalComponent, setModalComponent] = useState();
    const [data, setData] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false)
    const navigate = useNavigate();
    const [filters, setFilters] = useState([])
    const [loading, setLoading] = useState(false);
    const datePickerRef = useRef(null);
    const buttonRef = useRef(null);

    const filterCardRef = useRef(null);
    const filterButtonRef = useRef(null);

    const handleTableChange = (pagination, filters) => {
        if (carePartnerId || appointmentId) {
            setFilters(filters)
        } else {
            setCurrentPage(pagination.current)
            setPageLimit(pagination.pageSize)
            setData([])
            data.length = 0
            loadData(startDate, endDate, pagination.current, pagination.pageSize);
        }

    };
    const onSearch = (value, _e, info) => {
        // console.log(value)
        setSearchField(value)
    }

    const handleClickOutside = (event) => {
        if (
          datePickerRef.current &&
          !datePickerRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setShowDatePicker(false);
          
        }
    
        if (
          filterButtonRef.current &&
          !filterButtonRef.current.contains(event.target) &&
          filterCardRef.current &&
          !filterCardRef.current.contains(event.target)
        ) {
          setIsFilterVisible(false);
          
        }
      };

    const getSixDaysAgoFromCurrentDate = () => {
        const today = new Date();
        const sixDaysAgo = new Date(today);
        sixDaysAgo.setDate(today.getDate() - 6);
        return sixDaysAgo;
    };

    const [dateRange, setDateRange] = useState([
        {
            startDate: getSixDaysAgoFromCurrentDate(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const startDate = dateRange[0].startDate
    const endDate = dateRange[0].endDate
    const momentFormatStartDate = moment(startDate).format("D/M/YYYY");
    const momentFormatEndDate = moment(endDate).format("D/M/YYYY");


    const handleDateChange = (ranges) => {
        setDateRange([ranges.selection]);

    };

    const handleToggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => confirm()}
                    style={{ width: 138, marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        onClick={() => {
                            clearFilters();
                        }}
                        size="small"
                        style={{ width: 90, border: 0 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        size="small"
                        style={{ width: 40 }}
                    >
                        OK
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    });


    useEffect(() => {
        setCurrentPage(1)
        setTotalItems(1)
        setPageLimit(10)
        setData([])
        data.length = 0;
        loadData(startDate, endDate, 1, 10)

    }, [startDate, endDate, searchField]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    async function loadData(startDate, endDate, pageNo, pageSize) {
        // console.log("In Page")
        try {
            setLoading(true);
            if ((carePartnerId || appointmentId) && data.length === 0) {
                let details
                if (carePartnerId) {
                    details = await getReferralDetailsOfClinic(carePartnerId);

                } else {
                    details = await getReferralDetailsOfPatient(appointmentId);
                }
                // console.log(details)
                setData(
                    details.data.map((data) => {
                        // console.log(data)
                        const date = new Date();
                        return {
                            key: data.id,
                            id: data.id,
                            clinic: data["clinic.name"] ? data["clinic.name"] : "Test Clinic",
                            clinician: data.displayName,
                            patientname: data.patientFirstName + " " + data.patientLastName,
                            patient: [data.patientFirstName, data.patientLastName, data.patientEmail, data.patientPhone],
                            dateOrdered: data.dateOrdered,
                            description: data.description,
                            outOfNetworkReason: data.outOfNetworkReason
                        };
                    })
                );

            } else {
                const details = await getReferralDetails(startDate, endDate, pageNo, pageSize, searchField)
                setTotalItems(details.totalItems === 0 ? 1 : details.totalItems)
                if (details.data.length) {
                    setData(details.data.map((element) => {
                        // console.log(element)
                        const date = new Date();
                        return {
                            key: element.id,
                            id: element.id,
                            clinic: element["clinic.name"] ? element["clinic.name"] : "Test Clinic",
                            clinician: element.displayName,
                            patientname: element.patientFirstName + " " + element.patientLastName,
                            patient: [element.patientFirstName, element.patientLastName, element.patientEmail, element.patientPhone],
                            dateOrdered: element.dateOrdered,
                            description: element.description,
                            outOfNetworkReason: element.outOfNetworkReason
                        };
                    })
                    )

                }

            }


        } catch (e) {
            console.log("failed to load data", e)
            Modal.confirm({
                title: 'Error Message',
                content: 'Oops! Error in Fetching data',
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } },
                onOk: async () => {
                    console.log("Error in Fetching data");
                },
            });
        } finally {
            setLoading(false); // Reset loading once data fetching is complete
        }
        return true;
    }


    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleFilter = () => {
        setIsFilterVisible(true);
    }

    const handleClose = () => {
        setIsFilterVisible(false);
    };


    return (
        <div id="dashboard-container">

            {carePartnerId || appointmentId ? null : (
                <div className='row styleFilterRow'>
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        <div style={{ marginTop: 5 }}>
                            <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Referrals</span>
                            <Tag>
                                <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : totalItems}</strong>
                            </Tag>

                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <span className='dateStyling' style={{ marginRight: '10px' }}>Filter</span>
                            <div style={{ position: 'relative' }}>

                                <Tooltip title="Filter" >
                                    <FilterOutlined
                                        style={{ marginRight: "10px", fontSize: "18px", marginTop: "5px", cursor: "pointer" }}
                                        ref={filterButtonRef}
                                        onClick={handleFilter}
                                    />
                                </Tooltip>

                                {isFilterVisible && (
                                    <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}>
                                        <Card title="Filter Options" bordered={true} extra={<Button type="Close" onClick={handleClose}><CloseCircleOutlined /></Button>}>
                                            <FilterComponent searchField={searchField} setSearchField={setSearchField} from={"Referral"} />
                                        </Card>
                                    </div>
                                )}
                            </div>
                            <span className='dateStyling'>{momentFormatStartDate + ' to ' + momentFormatEndDate}</span>
                            <span ref={buttonRef} onClick={handleToggleDatePicker} className='marginAdjustCalendarToggleButton'>{showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}</span>
                            {showDatePicker && (
                                <div className="date-picker-container" ref={datePickerRef} style={{ position: 'absolute', zIndex: '9999', top: '10.5rem', display: 'flex', justifyContent: 'end', right: '30px' }}>
                                    <DateRangePicker
                                        ranges={dateRange}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            )}


                        </div>
                    </div></div>)}


            <Spin spinning={loading}>
                <Table
                    dataSource={data}
                    pagination={{
                        current: currentPage,
                        pageSize: 10,

                        total: totalItems,
                    }}
                    bordered={true}
                    onChange={handleTableChange}
                >
                    <Column
                        title="Clinic Name"
                        dataIndex="clinic"
                        key="clinic"

                    />
                    <Column
                        title="Referral Date"
                        dataIndex="dateOrdered"
                        key="dateOrdered"
                        width={130}
                        minWidth={130}
                        render={(dateOrdered) => (
                            <>
                                <p>{dateOrdered ? formatDateAndTimeInlocal(dateOrdered) : " "}</p>
                            </>
                        )}
                    />


                    <Column
                        title="Patient Name"
                        dataIndex="patient"
                        key="patient"

                        render={(patient) => (
                            <>
                                <strong>{patient[0] + " " + patient[1]}</strong>
                                <div>{patient[2]}</div>
                                <div>{patient[3]}</div>
                            </>
                        )}
                    />

                    <Column
                        title="Clinician"
                        dataIndex="clinician"
                        key="clinician"

                    />

                    <Column
                        title="Diagnosis Description"
                        dataIndex="description"
                        key="description"
                    />

                    <Column
                        title="Referral Reason"
                        dataIndex="outOfNetworkReason"
                        key="outOfNetworkReason"
                    />


                </Table>
            </Spin>
            <Modal
                title={modalTitle}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={true}
                centered={true}
                // closeIcon={<CloseOutlined onClick={() => setIsSubmitting(false)} />}
                style={{ minHeight: "300px", fontSize: "1rem" }}
            >
                {modalComponent}
            </Modal>
        </div>
    );
}

export default ReferralDetails;
