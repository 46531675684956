import axios from 'axios'
import { json } from 'react-router-dom'
import { BACKEND_URL } from '../utils/urls'
import { base64toBlob } from '../utils/base64ToBlob'

export default class IntakeService {
  static async getAllGHLUsers(
    searchField,
    pageNo,
    pageSize,
    startDate,
    endDate,
    filterClinic,
    filterChannel,
    hasBooking,
    hasConsent,
    hasCreditCard,
    hasInsurance,
    hasGeneralInfo
  ) {
    let reqOptions = {
      url: `${BACKEND_URL}/api/intake/getGhlContacts`,
      method: 'GET',
      params: {
        search: searchField ? searchField : null,
        limit: pageSize ? pageSize : null,
        pageNo: pageNo ? pageNo : null,
        pageSize: pageSize ? pageSize : null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        filterClinic: filterClinic ? filterClinic : null,
        channel: filterChannel ? filterChannel : null,
        hasBooking: hasBooking ? hasBooking : null,
        hasConsent: hasConsent ? hasConsent : null,
        hasCreditCard: hasCreditCard ? hasCreditCard : null,
        hasInsurance: hasInsurance ? hasInsurance : null,
        hasGeneralInfo: hasGeneralInfo ? hasGeneralInfo : null
      }
    }
    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      } else {
        throw json({ message: 'Could not fetch events.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch events.' }, { status: 500 })
    }
  }

  static async getGHLUserById(id) {
    let reqOptions = {
      url: `${BACKEND_URL}/api/intake/getGhlContactById`,
      method: 'GET',
      params: {
        id
      }
    }
    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      } else {
        throw json({ message: 'Could not fetch events.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch events.' }, { status: 500 })
    }
  }

  static async getDocument(record, pdfType) {
    let reqOptions = {
      url: `${BACKEND_URL}/api/intake/getAMDPdf`,
      method: 'GET',
      params: {
        id: record.amdPatientID,
        pdfType: pdfType,
        universeId: record.id
      }
    }

    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const base64Data = response.data
        console.log({ base64Data })
        if (base64Data.error) {
          return base64Data?.error
        }
        const blobData = base64toBlob(base64Data, 'application/pdf')
        return blobData
      } else {
        throw json({ message: 'Could not fetch events.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch events.' }, { status: 500 })
    }
  }

  static async getAllAppointment(record) {
    let reqOptions = {
      url: `${BACKEND_URL}/api/intake/getApptHistory`,
      method: 'GET',
      params: {
        amdPatientID: record.amdPatientID,
        firstname: record.firstname,
        lastname: record.lastname,
        dob: record.dob,
        email: record.email,
        phone_no: record.phone_no
      }
    }

    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      } else {
        throw json({ message: 'Could not fetch events.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch events.' }, { status: 500 })
    }
  }

  static async getAllClinics() {
    let reqOptions = {
      url: `${BACKEND_URL}/api/intake/getAllClinics`,
      method: 'GET'
    }

    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      } else {
        throw json({ message: 'Could not fetch clinics.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch clinics.' }, { status: 500 })
    }
  }

  static async getAllChannels() {
    let reqOptions = {
      url: `${BACKEND_URL}/api/intake/getAllChannels`,
      method: 'GET'
    }

    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      } else {
        throw json({ message: 'Could not fetch clinics.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch clinics.' }, { status: 500 })
    }
  }

  static async sendIntakeEmailAndSMS(contactId, link) {
    const url = `${BACKEND_URL}/api/intake/sendIntakeEmailandSMS`
    const body = {
      contactId,
      link
    }
    try {
      const response = await axios.post(url, body)
      return response?.status === 200 && response?.data?.status === 200
    } catch (error) {
      console.error('Error:', error)
      throw new Error('Oops! An error occurred while inserting contact data.')
    }
  }

  static async sendConsentCCIntakeEmailAndSMS(contactId, link) {
    const url = `${BACKEND_URL}/api/intake/sendConsentCCIntakeEmailandSMS`
    const body = {
      contactId,
      link
    }
    try {
      const response = await axios.post(url, body)
      return response?.status === 200 && response?.data?.status === 200
    } catch (error) {
      console.error('Error:', error)
      throw new Error('Oops! An error occurred while inserting contact data.')
    }
  }

  static async getProviders() {
    let reqOptions = {
      url: `${BACKEND_URL}/api/providers`,
      method: 'GET'
    }
    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      } else {
        throw json({ message: 'Could not fetch providers.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch providers.' }, { status: 500 })
    }
  }

  static async getAllAppointmentTypes() {
    let reqOptions = {
      url: `${BACKEND_URL}/api/appointmentTypes`,
      method: 'GET'
    }

    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      } else {
        throw json({ message: 'Could not fetch events.' }, { status: 500 })
      }
    } catch {
      throw json({ message: 'Could not fetch events.' }, { status: 500 })
    }
  }

  static async getStates() {
    const response = await axios.get(`${BACKEND_URL}/api/states`)
    if (response.status === 200 && response.data) {
      const resData = await response.data
      return resData
    } else {
      throw json(
        { message: 'Could not fetch events.' },
        {
          status: 500
        }
      )
    }
  }
}
