import axios from "axios";
import { json } from "react-router-dom";
import { BACKEND_URL } from "../utils/urls";

export default class GHLService {
  async getAllGHLUsers() {
    const response = await axios.get(`${BACKEND_URL}/api/ghl/users`);
    if (response.status === 200 && response.data) {
      const resData = await response.data;
      return resData;
    } else {
      throw json(
        { message: "Could not fetch events." },
        {
          status: 500,
        }
      );
    }
  }

  async getAllGHLCalendars() {
    const response = await axios.get(`${BACKEND_URL}/api/ghl/calendars`);
    if (response.status === 200 && response.data) {
      const resData = await response.data;
      return resData;
    } else {
      throw json(
        { message: "Could not fetch events." },
        {
          status: 500,
        }
      );
    }
  }

  async insertOrupdateGhlContact(intent, formData, contact_id) {
    const { firstname, lastname, dob, phone_no, email, from, tags } = formData;
    const url = `${BACKEND_URL}/api/ghl/createOrUpdateContact`;
    const body = {
      intent: intent,
      contact_id: contact_id,
      payload: {
        firstName: firstname,
        lastName: lastname,
        dateOfBirth: dob,
        phone: phone_no,
        email,
        from,
        tags
      },
    };
    try {
      const response = await axios.post(url, body);
      return response?.data;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Oops! An error occurred while inserting contact data.");
    }
  }

  async findGhlContact(formData) {
    const { firstName, lastName, dob, phone, email } = formData;
    const url = `${BACKEND_URL}/api/ghl/findContact`;
    const body = {
      payload: {
        firstName,
        lastName,
        dateOfBirth: dob,
        phone,
        email
      },
    };
    try {
      const response = await axios.post(url, body);
      return response?.data;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Oops! An error occurred while inserting contact data.");
    }
  }
}

export const { getAllGHLUsers, getAllGHLCalendars, insertOrupdateGhlContact, findGhlContact } = new GHLService();
