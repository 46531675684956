import axios from 'axios'
import { json } from 'react-router-dom'
import { BACKEND_URL } from '../utils/urls'

export default class GHLTagMasterService {
  async getAllGHLTagMaster() {
    const response = await axios.get(`${BACKEND_URL}/api/ghlTagMasters`)
    if (response.status === 200 && response.data) {
      const resData = await response.data
      return resData
    } else if (response.status === 200 && !response.data) {
      return null
    } else {
      throw json(
        { message: 'Could not fetch events.' },
        {
          status: 500
        }
      )
    }
  }
}

export const { getAllGHLTagMaster } = new GHLTagMasterService()
