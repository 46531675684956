import { atom } from 'recoil';

const getSixDaysAgoFromCurrentDate = () => {
    const today = new Date();
    const sixDaysAgo = new Date(today);
    sixDaysAgo.setDate(today.getDate() - 6);
    return sixDaysAgo;
};

export const entityOptionState = atom({
    key: 'entityOptionState',
    default: 'all',
});

export const appointmentTypeOptionState = atom({
    key: 'appointmentTypeOptionState',
    default: ['all'],
});

export const appointmentStatusOptionState = atom({
    key: 'appointmentStatusOptionState',
    default: [],
});

export const dateRangeState = atom({
    key: 'dateRangeState',
    default: [
        {
            startDate: getSixDaysAgoFromCurrentDate(),
            endDate: new Date(),
            key: 'selection',
        },
    ],
})

