import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from "../utils/urls"

export default class ClinicsService {
    async getClinics(clinic, alias, typeOfScreening, typeOfEhr) {
        const url = `${BACKEND_URL}/api/carePartners`
        const config = {
            headers: { 'Content-Type': 'application/json' },
            params: {
                clinic: clinic ? clinic : null,
                typeOfScreening:  typeOfScreening ? typeOfScreening: null,
                typeOfEhr: typeOfEhr? typeOfEhr : null,
                alias: alias? alias: null
            }
        };
        
        try {
            const response = await axios.get(url, config)
            if (response.status === 200 && response.data) {
                const resData = await response.data;
                return resData;
            } 
            return []
        } catch (error) {
            console.log(error)
            return []
        }
   
        
    }

    async getClinicDetails(id) {
        const response = await axios.get(`${BACKEND_URL}/api/carePartners/${id}`)
        if (response.status === 200 && response.data) {
            const resData = await response.data;
            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async deleteClinic(id) {
        try {
            const response = await axios.delete(`${BACKEND_URL}/api/carePartners/${id}`);
            if (response.status === 200 && response.data) {
                return response.data;
            } else {
                throw new Error("Could not delete clinic.");
            }
        } catch (error) {
            throw new Error("Could not delete clinic.");
        }
    }

    async updateClinic(id, clinicData) {
        try {
            let headersList = {
                "Content-Type": "multipart/form-data"
            }
            const response = await axios.put(`${BACKEND_URL}/api/carePartners/${id}`, clinicData, { headers: headersList });
            if (response.status === 200 && response.data) {
                return response.data;
            } else {
                throw new Error('Could not update data')
            }
        } catch (error) {

            console.log(error.response.data.error)
            let errorMessage = '';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error.message;
            }
    
            throw new Error(errorMessage);
        }
    }

    async insertClinic(data) {
        try{
            let headersList = {
                "Content-Type": "multipart/form-data"
            }
    
            let reqOptions = {
                url: `${BACKEND_URL}/api/carePartners`,
                method: "POST",
                headers: headersList,
                data: data,
            }
    
            let response = await axios.request(reqOptions);
            if (response.status === 201 && response.data) {
                const resData = await response.data;
                // console.log(resData)
                return resData;
    
            } else {
                throw json(
                    { message: 'Could not fetch events.' },
                    {
                        status: 500,
                    }
                );
            }

        }catch(error){
            console.log(error.response.data.error)
            let errorMessage = '';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error.message;
            }
    
            throw new Error(errorMessage);
        }
       
    }
}

export const { getClinics, insertClinic, deleteClinic, getClinicDetails, updateClinic } = new ClinicsService();