import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {
    Table,
    TableHeader,
    TableCell,
    TableBody,
    DataTableCell,
} from '@david.kucsai/react-pdf-table';
import { formatDateAndTime } from '../utils/dateFormat';



// Create styles
const styles = StyleSheet.create({
    page: {

        backgroundColor: '#fff',
    },
    section: {
        margin: 10,
        padding: 10,
        textAlign: 'center',
        fontSize: 40
    },
});

function MusterSheet({ data, from }) {
    return (
        <Document title="Muster">
            {from === "Athena" ?
                <Page size="A0" style={styles.page}>

                    <View style={styles.section} >
                        <Text >Athean Connector Appointment</Text>
                    </View>
                    <Table data={data}>
                        <TableHeader textAlign="center" fontSize={30}>
                            <TableCell>Sr. No.</TableCell>
                            <TableCell>Patient First Name</TableCell>
                            <TableCell>Patient Last Name</TableCell>
                            <TableCell>Patient Phone No.</TableCell>
                            <TableCell>Patient Email</TableCell>
                            {/* <TableCell>Role</TableCell> */}
                        </TableHeader>
                        <TableBody textAlign="center" fontSize={20}>
                            <DataTableCell getContent={(r) => r.id} />
                            <DataTableCell getContent={(r) => r.patientname} />
                            <DataTableCell getContent={(r) => r.phone} />
                            <DataTableCell getContent={(r) => r.email} />

                            {/* <DataTableCell getContent={(r) => r.userRole} /> */}
                        </TableBody>
                    </Table>
                </Page> : <Page size="A0" style={styles.page}>

                    <View style={styles.section} >
                        <Text >Screening Details</Text>
                    </View>
                    <Table data={data}>
                        <TableHeader textAlign="center" fontSize={20}>
                            <TableCell>Sr. No.</TableCell>
                            <TableCell>Clinic</TableCell>
                            <TableCell>Referral Date</TableCell>
                            <TableCell>Patient Name</TableCell>
                            <TableCell>Patient Phone No.</TableCell>
                            <TableCell style={{ innerWidth: 100 }}>Patient Email</TableCell>
                            <TableCell>Contact Method</TableCell>
                            <TableCell>Date of Birth</TableCell>
                            <TableCell>Baby Date</TableCell>
                            <TableCell>Insurances</TableCell>
                            <TableCell>Pregnancy Status</TableCell>
                            <TableCell>Clinician</TableCell>
                            <TableCell>EPDS Score</TableCell>
                            <TableCell>GDS Score</TableCell>
                            <TableCell>GAD-7 Score</TableCell>
                            <TableCell>PHQ-9 Score</TableCell>

                            {/* <TableCell>Role</TableCell> */}
                        </TableHeader>
                        <TableBody textAlign="center" fontSize={15}>
                            <DataTableCell getContent={(r) => r.id} />
                            <DataTableCell getContent={(r) => r.clinic} />
                            <DataTableCell getContent={(r) => formatDateAndTime(r.date)} />
                            <DataTableCell getContent={(r) => r.patientName} />
                            <DataTableCell getContent={(r) => r.phone} />
                            <DataTableCell getContent={(r) => r.email} />
                            <DataTableCell getContent={(r) => r.contactMethod} />
                            <DataTableCell getContent={(r) => r.dob} />
                            <DataTableCell getContent={(r) => r.babyDate} />
                            <DataTableCell getContent={(r) => r.insurances} />
                            <DataTableCell getContent={(r) => r.pregnancyStatus} />
                            <DataTableCell getContent={(r) => r.clinician} />
                            <DataTableCell getContent={(r) => r.epdsScore} />
                            <DataTableCell getContent={(r) => r.gdsScore} />
                            <DataTableCell getContent={(r) => r.gad7Score} />
                            <DataTableCell getContent={(r) => r.phq9Score} />

                            {/* <DataTableCell getContent={(r) => r.userRole} /> */}
                        </TableBody>
                    </Table>
                </Page>
            }
        </Document>
    );
}

export default MusterSheet;