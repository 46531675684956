import React, { useState, useEffect, useRef } from "react";
import "../styles/Details.css";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip, Spin, Card, Input, Dropdown } from "antd";
import { getPartnerPatient, getUniqueClinicName } from "../services/PartnerPatientService";
import { formatDate, formatDateAndTimeInlocal } from "../utils/dateFormat";

import FilterComponent from "../components/FilterComponent";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import useDebounce from "../hooks/useDebounce";
import CalendarGrey from "../assets/Calendar Dark.svg";
import CalendarBlue from "../assets/Calendar Blue.svg";

const { Column } = Table;
const { Search } = Input;

function PartnerPatientDetails() {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [clinics, setClinics] = useState([]);
  const [clinicName, setClinicName] = useState(null);
  const [referralPlatform, setReferralPlatform] = useState(null);
  const filterCardRef = useRef(null);
  const filterButtonRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const buttonRef = useRef(null);
  const datePickerRef = useRef(null);

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination.current);
    setPageLimit(pagination.pageSize);
    setData([]);
    data.length = 0;
    loadData(pagination.current, pagination.pageSize);
  };


  const getSixDaysAgoFromCurrentDate = () => {
    const today = new Date();
    const sixDaysAgo = new Date(today);
    sixDaysAgo.setDate(today.getDate() - 6);
    return sixDaysAgo;
  };


  const [dateRange, setDateRange] = useState([
    {
      startDate: getSixDaysAgoFromCurrentDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const startDate = dateRange[0].startDate;
  const endDate = dateRange[0].endDate;
  const momentFormatStartDate = moment(startDate).format("D/M/YYYY");
  const momentFormatEndDate = moment(endDate).format("D/M/YYYY");

  const debouncedSearchValue = useDebounce(searchText);
  useEffect(() => {
    setCurrentPage(1);
    setPageLimit(10);
    setData([]);
    data.length = 0;
    loadData(1, 10);
  }, [referralPlatform, clinicName, debouncedSearchValue, startDate, endDate]);

  async function loadData(pageNo, pageSize) {
    try {
      setLoading(true);
      const clinicNames = await getUniqueClinicName();
      setClinics(clinicNames);
      const details = await getPartnerPatient(pageNo, pageSize, referralPlatform, clinicName, debouncedSearchValue, startDate, endDate);
      setTotalItems(details.totalItems === 0 ? 1 : details.totalItems);
      if (details.data.length) {
        console.log(details);
        setData(
          details.data.map((element) => {
            return {
              key: element?.id,
              id: element?.id,
              patientname: element?.firstName + " " + element?.lastName,
              email: !element?.email || element?.email?.includes("notfound") ? "--" : element?.email,
              phone: !element?.phoneNo || element?.phoneNo === "00000000" ? "--" : element?.phoneNo,
              address: element?.address,
              city: element?.city,
              state: element?.state,
              addressInfo: [element?.address, element?.city, element?.state],
              contactInfo: [
                !element?.email || element?.email?.includes("notfound") ? "--" : element?.email,
                element?.patientPhone === "00000000" ? "--" : element.patientPhone,
              ],
              insurances: element?.patientInsuranceDetails ? element?.patientInsuranceDetails[0] : null,
              dob: element?.dob,
              referralPlatform: element?.referralPlatform,
              clinic: element?.clinicName,
              date: element?.createdAt,
              primaryProvider: element?.primaryProviderName ? element?.primaryProviderName : null,
            };
          })
        );
      }
    } catch (e) {
      console.log("failed to load data from database", e);
      Modal.confirm({
        title: "Error Message",
        content: "Oops! Error in Fetching data",
        okText: "Ok",
        cancelButtonProps: { style: { display: "none" } },
        onOk: async () => {
          console.log("Error in Fetching data");
        },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
    return true;
  }

  const handleClickOutside = (event) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false);
    }

    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleToggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleFilter = () => {
    setIsFilterVisible(true);
  };

  const handleClose = () => {
    setIsFilterVisible(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  function handleInsuranceDetails(insurance) {
    // const ins = JSON.parse(insurance)
    insurance = insurance ? insurance : {};
    setModalTitle("Insurance Details");
    setModalComponent(
      <div>
        <div>
          <strong>Insurance Holder Name :</strong> {insurance.insurancepolicyholder}
        </div>
        <div>
          <strong>IRC Name :</strong> {insurance.ircname}
        </div>
        <div>
          <strong>Insurance Plan Name : </strong>
          {insurance.insuranceplanname}
        </div>
        <div>
          <strong>Insured Holder DOB : </strong>
          {insurance.insurancepolicyholderdob}
        </div>
        <div>
          <strong>Insurance Group Id :</strong> {insurance.policynumber}
        </div>
        <div>
          <strong>Insured Member Id : </strong>
          {insurance.insuranceidnumber}
        </div>
      </div>
    );
    setIsModalOpen(true);
  }

  return (
    <div id="dashboard-container">
      {/* Add Clinic Filter */}
      <div className="row styleFilterRow">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: 5 }}>
            <span
              style={{
                marginLeft: "18px",
                fontWeight: 500,
                marginRight: 10,
                marginTop: 13,
              }}
            >
              Total Patients
            </span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{totalItems}</strong>
            </Tag>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span className="dateStyling" style={{ marginRight: "10px" }}>
              Filter
            </span>
            <div style={{ position: "relative" }}>
              <Tooltip title="Filter">
                <FilterOutlined
                  style={{
                    marginRight: "10px",
                    fontSize: "18px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  ref={filterButtonRef}
                  onClick={handleFilter}
                />
              </Tooltip>

              {isFilterVisible && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 100,
                  }}
                  ref={filterCardRef}
                >
                  <Card
                    title="Filter Options"
                    bordered={true}
                    extra={
                      <Button type="Close" onClick={handleClose}>
                        <CloseCircleOutlined />
                      </Button>
                    }
                  >
                    <FilterComponent
                      ehrType={referralPlatform}
                      setEhrType={setReferralPlatform}
                      clinicNames={clinics}
                      filteredClinic={clinicName}
                      setFilteredClinic={setClinicName}
                      from={"Partner Patient"}
                      searchField={searchText}
                      setSearchField={setSearchText}
                    />
                  </Card>
                </div>
              )}
            </div>
            <span className="dateStyling">{momentFormatStartDate + " to " + momentFormatEndDate}</span>
            <span
              ref={buttonRef}
              onClick={handleToggleDatePicker}
              className="marginAdjustCalendarToggleButton"
              style={{ cursor: "pointer" }}
            >
              {showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}
            </span>
            {showDatePicker && (
              <div
                className="date-picker-container"
                ref={datePickerRef}
                style={{
                  position: "absolute",
                  zIndex: "9999",
                  top: "10.5rem",
                  display: "flex",
                  justifyContent: "end",
                  right: "30px",
                }}
              >
                <DateRangePicker ranges={dateRange} onChange={handleDateChange} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {isMobileView ? (<Spin spinning={loading}>
                {currentData.map((record) => renderMobileCard(record))}

                <Pagination
                    total={filteredData.length}
                    current={currentPage}
                    pageSize={pageLimit}
                    showSizeChanger={false}
                    onChange={handleTableChange} /> </Spin>)
                : */}
      <Spin spinning={loading}>
        <Table
          dataSource={data}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: pageLimit,
          }}
          bordered={true}
          onChange={handleTableChange}
        >
          <Column
            title="Referral Date"
            dataIndex="date"
            key="date"
            width={130}
            render={(date) => (
              <>
                <div>{date ? formatDateAndTimeInlocal(date) : " "}</div>
              </>
            )}
          />
          <Column
            title="Patient"
            key="patientname"
            render={(record) => (
              <div>
                <strong>{record.patientname}</strong>
                <div>{record.dob ? formatDate(record.dob) : " "}</div>
              </div>
            )}
          />

          <Column
            title="Contact"
            key="contactInfo"
            render={(record) => (
              <div>
                <div>{record.email}</div>
                <div>{record.phone}</div>
              </div>
            )}
          />

          <Column
            title="Address"
            key="addressInfo"
            render={(record) => (
              <div>
                <div>{record.address}</div>
                <div>
                  {record.city}, {record.state}
                </div>
              </div>
            )}
          />

          <Column
            title="Payment Mode"
            dataIndex="insurances"
            key="insurances"
            render={(insurances) => (
              <>
                {insurances ? (
                  <div>
                    {" "}
                    {insurances.ircname !== "Self-Pay (cash)" && insurances.ircname !== "" ? (
                      <Tooltip title="View Insurance Details">
                        <Button onClick={() => handleInsuranceDetails(insurances)}>Details</Button>{" "}
                      </Tooltip>
                    ) : (
                      "Self-Pay"
                    )}
                  </div>
                ) : (
                  <p style={{ paddingLeft: "10px" }}>Self-Pay</p>
                )}
              </>
            )}
          />

          <Column title="Source" dataIndex="referralPlatform" key="referralPlatform" />

          <Column title="Clinic" dataIndex="clinic" key="clinic" />

          <Column title="Primary Clinician" dataIndex="primaryProvider" key="primaryProvider" />
        </Table>
      </Spin>
      {/* } */}

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
        centered={true}
        // closeIcon={<CloseOutlined onClick={() => setIsSubmitting(false)} />}
        style={{ minHeight: "300px", fontSize: "1rem" }}
      >
        {modalComponent}
      </Modal>
    </div>
  );
}

export default PartnerPatientDetails;
