import React, { useCallback, useEffect, useState } from "react";
import "../styles/Details.css";
import { Card, Modal, message, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { getCaseManagerLinks } from '../services/InsuranceCaseManagerService';

function InsuranceCaseManager() {
    document.title = "LunaJoy Dashboard";
    const [caresourceUrl, setCaresourceUrl] = useState('')
    const [sunshineHealthUrl, setSunshineHealthUrl] = useState('')

    const loadData = useCallback(async () => {
        try {
            const links = await getCaseManagerLinks()
            links?.forEach(link => {
                if (link?.insurance === 'Caresource') {
                    setCaresourceUrl(link?.url)
                } else if (link?.insurance === 'Sunshine Health') {
                    setSunshineHealthUrl(link?.url)
                }
            });
        } catch (e) {
            console.log("failed to load data from database", e)
            Modal.confirm({
                title: 'Error Message',
                content: 'Oops! Error in Fetching data',
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } },
                onOk: async () => {
                    console.log("Error in Fetching data");
                },
            });
        }
    }, [])

    useEffect(() => {
        loadData()
    }, [loadData])

    const copyLink = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => message.success('Link copied to clipboard'))
            .catch(() => message.error('Failed to copy link'));
    }

    return (
        <div id="dashboard-container" className="report-container"  >
            <Card style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                <p style={{ fontSize: '20px' }}><strong>Please visit following links to book an appointment: </strong></p>
                <p style={{ fontSize: '20px', marginBottom: '0px' }}>
                    <strong>Caresource Case Manager link: </strong>
                    <a href={caresourceUrl} target="_blank" rel="noreferrer" style={{ marginRight: '0.5rem' }}>{caresourceUrl}</a>
                    <Tooltip title={"Copy link"}><CopyOutlined onClick={() => copyLink(caresourceUrl)} /></Tooltip>
                </p>
                <p style={{ fontSize: '20px', marginBottom: '0px' }}>
                    <strong>Sunshine Health Case Manager link: </strong>
                    <a href={sunshineHealthUrl} target="_blank" rel="noreferrer" style={{ marginRight: '0.5rem' }}>{sunshineHealthUrl}</a>
                    <Tooltip title={"Copy link"}><CopyOutlined onClick={() => copyLink(sunshineHealthUrl)} /></Tooltip>
                </p>
            </Card>
        </div>
    );
}

export default InsuranceCaseManager;