export const validateForm = (data) => {
    console.log(data)
    const errors = {};

    if(data?.doxy_link){
       
        if(!data.doxy_link.startsWith('https://doxy.me/')){
            errors.doxy_link = `Invaild doxy link (use url that startswith https://doxy.me/)`;
        }
        
    }

    if(data?.referralLink){
        if (!isValidUrl(data.referralLink)) {
            errors.referralLink = 'Invalid referral link URL';
        }
    }

    if (data?.faxNumber) {
        // Remove hyphens from the entered fax number
        const cleanedFaxNumber = data.faxNumber.replace(/-/g, '');

        // Check if the cleaned fax number has exactly 10 digits
        if (!/^\d{10}$/.test(cleanedFaxNumber)) {
            errors.faxNumber = 'Invalid fax number (use format: XXX-XXX-XXXX)';
        } else {
            data.faxNumber = `${cleanedFaxNumber.slice(0, 3)}-${cleanedFaxNumber.slice(3, 6)}-${cleanedFaxNumber.slice(6)}`;
        }
    }

    if (data?.logo) {
        if (data.logo?.fileList[0]?.status === "error") {
            errors.logo = data.logo.fileList[0].error
        }
    }

    if(!data?.faxSequence?.length && data.isFaxScheduled) {
        errors.faxSequence = 'Select appropriate fax sequence before enabling fax scheduling'
    }

    if(!data?.faxNumber && data.isFaxScheduled) {
        errors.faxNumber = 'Please enter fax number before enabling fax scheduling'
    }

    if(!data?.ehrId && data.typesOfIntegration) {
        errors.typesOfIntegration = 'Please enter practice id before enabling sync'
    }

    if(data?.insuranceState && data.insuranceState?.length){
        const uniqueStates = new Set();

        console.log(data)
        data.insuranceState.forEach((element, index) => {
            // Check if states are not the same
            if(element?.states){
                if (uniqueStates.has(element.states)) {
                    errors.insuranceState = `Duplicate state found.`;
                } else {
                    uniqueStates.add(element.states);
                }
        
                // Check if insurances is not undefined
                if (!element?.insurances && !element.insurances?.length) {
                    errors.insuranceState = `Insurances is not selected, please select at least one.`;
                }
            }
            if(element?.insurances && element?.insurances?.length){
                if(!element.states){
                    errors.insuranceState = `State is not selected, please select a state.`;
                }
            } 
        });
    
    }

    return errors;
};

function isValidUrl(string) {
    try {
        new URL(string);
        return true; 
    } catch (e) {
        return false; 
    }
}