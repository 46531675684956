export function convertToTitleCase(inputString) {
  if (!inputString) return "";

  const parts = inputString.split(",");

  const result = parts
    .map((part) => {
      const words = part.split(" ");
      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
      return capitalizedWords.join(" ");
    })
    .join(" ");

  return result;
}

export function capitalizeFirstLetter(inputString) {
  if (inputString.length === 0) return inputString;
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function getInitials(string) {
  try {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    const initials = [...string.matchAll(rgx)] || [];
    return ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
  } catch (e) {}
}
