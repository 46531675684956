import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

const generate = (errorReason) => {
  let title

  switch (errorReason) {
    case 'email':
      title = "We Couldn't Match All Your Info, Let's Double-Check"
      break
    case 'phone':
      title = "We Couldn't Match All Your Info, Let's Double-Check"
      break
    case 'email_phone':
      title = "We Couldn't Match All Your Info, Let's Double-Check"
      break
    case 'invalid_email':
      title = 'The Email Already Exists'
      break
    case 'invalid_phone':
      title = 'The Phone Number Already Exists'
      break
    case 'invalid_email_phone':
      title = 'The Email And Phone Number Already Exists'
      break
    default:
      break
  }

  const helpMessage = 'If you are still having trouble, please call or text (727)-291-9538.'

  const renderErrorContent = () => {
    if (errorReason === 'phone') {
      return (
        <>
          <p>Welcome back! For your security, we noticed that some details do not match our records. Please check again and resubmit.</p>
          <br />
          <p>First Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Last Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Date of Birth: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
          <p>Email: <CheckCircleFilled style={{ color: 'green' }} /></p>
        </>
      )
    }
    if (errorReason === 'email') {
      return (
        <>
          <p>Welcome back! For your security, we noticed that some details do not match our records. Please check again and resubmit.</p>
          <br />
          <p>First Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Last Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Date of Birth: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Phone: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
        </>
      )
    }
    if (errorReason === 'email_phone') {
      return (
        <>
          <p>Welcome back! For your security, we noticed that some details do not match our records. Please check again and resubmit.</p>
          <br />
          <p>First Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Last Name: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Date of Birth: <CheckCircleFilled style={{ color: 'green' }} /></p>
          <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
          <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
        </>
      )
    }
    if (errorReason === 'invalid_email_phone') {
      return (
        <>
          <p>Your email and phone number are already linked to a patient&apos;s account. Please try a different email and phone number to continue.</p>
          <br />
          <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
          <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
        </>
      )
    }
    if (errorReason === 'invalid_phone') {
      return (
        <>
          <p>Your phone number is already linked to a patient&apos;s account. Please try a different phone number to continue.</p>
          <br />
          <p>Phone: <CloseCircleFilled style={{ color: 'red' }} /></p>
        </>
      )
    }
    if (errorReason === 'invalid_email') {
      return (
        <>
          <p>Your email is already linked to a patient&apos;s account. Please try a different email to continue.</p>
          <br />
          <p>Email: <CloseCircleFilled style={{ color: 'red' }} /></p>
        </>
      )
    }
    return null
  }

  return { title, content: renderErrorContent(), helpMessage }
}

export default generate