import React from 'react';
import CarePartnerForm from '../components/CarePartnerForm';

function AddClinic() {
    return (
        <div id="dashboard-container" style={{ width: "700px", display: "flex", flexDirection: "column" }}>
            <h1 style={{ marginBottom: "20px" }}>Clinic Onboarding</h1>
            <CarePartnerForm />
        </div>
    );
}
export default AddClinic;