import { Modal } from "antd";
import React from "react";

const BookFollowupModal = ({ isBookFollowupModalOpen, bookFollowupModalContent, title }) => {
  return (
    <Modal
      styles={{ header: { textAlign: "center", } }}
      title={title}
      open={isBookFollowupModalOpen}
      closeIcon={false}
      centered={true}
      footer={null}
      width={720}
    >
      {bookFollowupModalContent}
    </Modal>
  );
};

export default BookFollowupModal;
