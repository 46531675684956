import { eachDayOfInterval, endOfMonth, endOfWeek, formatISO, startOfMonth, startOfWeek } from 'date-fns'
import Day from './Day'
import './Days.css'

function hasAvailableTime(availableDays, day) {
  return Boolean(availableDays?.find((availableDay) => availableDay === day))
}

function isSelected(day, selectedDay) {
  return Boolean(selectedDay && day === selectedDay)
}

const Days = ({ availableDays, date, selectedDay, setSelectedDay }) => {
  const startDate = startOfWeek(startOfMonth(date), { weekStartsOn: 1 })
  const endDate = endOfWeek(endOfMonth(date), { weekStartsOn: 1 })
  const days = eachDayOfInterval({ start: startDate, end: endDate })
    .map((day) => formatISO(day, { representation: 'date' }))

  return (
    <ul className='days'>
      {days.map((day) => (
        <Day
          key={day}
          date={date}
          day={day}
          hasAvailableTime={hasAvailableTime(availableDays, day)}
          isSelected={isSelected(day, selectedDay)}
          setSelectedDay={setSelectedDay}
        />
      ))}
    </ul>
  )
}

export default Days
