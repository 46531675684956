import { ZodIssueCode } from 'zod'
import { schema } from './schema'
import { ZodError } from 'zod'


const pluralize = (count, noun) => (
  `${count} ${noun}${count !== 1 ? 's' : ''}`
)

export const customErrorMap = (error, context) => {
  if (error.code === ZodIssueCode.invalid_type) {
    if (error.received === 'undefined') {
      return { message: 'This field is required' }
    }
  }

  if (error.code === ZodIssueCode.invalid_string) {
    if (error.validation === 'email') {
      return { message: 'Must be a valid email' }
    }
  }

  if (error.code === ZodIssueCode.invalid_literal) {
    if (error.expected === true) {
      return { message: 'Required' }
    }
  }

  if (error.code === ZodIssueCode.invalid_enum_value) {
    return { message: `Must be one of valid values: ${error.options.join(', ')}` }
  }

  if (error.code === ZodIssueCode.too_small) {
    return { message: `Must contain at least ${pluralize(error.minimum, 'character')}` }
  }

  if (error.code === ZodIssueCode.custom) {
    return { message: error.message }
  }

  return { message: context.defaultError }
}

function formatDate(inputDate) {
  if (!inputDate) {
    return undefined
  }
  const parts = inputDate.split('-');
  const formattedDate = parts[1] + '/' + parts[2] + '/' + parts[0];
  return formattedDate;
}

export const getValidator = (field, defaultError) => ([{
  validator: (_, value) => {
    if (value && field !== 'dob') {
      value = value.toString()
    } else if (field !== 'dob') {
      value = undefined
    }
    try {
      if (field === 'dob') {
        schema[field].parse(formatDate(value?.format()?.split('T')?.[0]))
      } else {
        schema[field].parse(value)
      }
    } catch (error) {
      if (error instanceof ZodError) {
        return Promise.reject(new Error(error.format()._errors[0]));
      }
      return Promise.reject(new Error(defaultError));
    }
    return Promise.resolve();
  },
}])

function processValue(value) {
  if (typeof value === 'string') {
    return value.trim()
  }

  return value
}

export default function validateField(schema) {
  return (value) => {
    try {
      schema.parse(processValue(value))

      return null
    } catch (error) {
      if (error instanceof ZodError) {
        // eslint-disable-next-line no-underscore-dangle
        return error.format()._errors[0]
      }

      return 'Validation error'
    }
  }
}