export const timezones = [
  { title: 'Alabama', short: 'AL', timezone: 'US/Central' },
  { title: 'Alaska', short: 'AK', timezone: 'US/Alaska' },
  { title: 'Arizona', short: 'AZ', timezone: 'US/Mountain' },
  { title: 'Arkansas', short: 'AR', timezone: 'US/Central' },
  { title: 'California', short: 'CA', timezone: 'America/Los_Angeles' },
  { title: 'Colorado', short: 'CO', timezone: 'America/Denver' },
  { title: 'Connecticut', short: 'CT', timezone: 'US/Eastern' },
  { title: 'Delaware', short: 'DE', timezone: 'US/Eastern' },
  { title: 'District of Columbia', short: 'DC', timezone: 'US/Eastern' },
  { title: 'Florida', short: 'FL', timezone: 'US/Eastern' },
  { title: 'Georgia', short: 'GA', timezone: 'US/Eastern' },
  { title: 'Hawaii', short: 'HI', timezone: 'Pacific/Honolulu' },
  { title: 'Idaho', short: 'ID', timezone: 'US/Mountain' },
  { title: 'Illinois', short: 'IL', timezone: 'US/Central' },
  { title: 'Indiana', short: 'IN', timezone: 'US/Eastern' },
  { title: 'Iowa', short: 'IA', timezone: 'US/Central' },
  { title: 'Kansas', short: 'KS', timezone: 'US/Central' },
  { title: 'Kentucky', short: 'KY', timezone: 'US/Eastern' },
  { title: 'Louisiana', short: 'LA', timezone: 'US/Central' },
  { title: 'Maine', short: 'ME', timezone: 'US/Eastern' },
  { title: 'Maryland', short: 'MD', timezone: 'US/Eastern' },
  { title: 'Massachusetts', short: 'MA', timezone: 'US/Eastern' },
  { title: 'Michigan', short: 'MI', timezone: 'US/Eastern' },
  { title: 'Minnesota', short: 'MN', timezone: 'US/Central' },
  { title: 'Mississippi', short: 'MS', timezone: 'US/Central' },
  { title: 'Missouri', short: 'MO', timezone: 'US/Central' },
  { title: 'Montana', short: 'MT', timezone: 'US/Mountain' },
  { title: 'Nebraska', short: 'NE', timezone: 'US/Central' },
  { title: 'Nevada', short: 'NV', timezone: 'US/Pacific' },
  { title: 'New Hampshire', short: 'NH', timezone: 'US/Eastern' },
  { title: 'New Jersey', short: 'NJ', timezone: 'US/Eastern' },
  { title: 'New Mexico', short: 'NM', timezone: 'US/Mountain' },
  { title: 'New York', short: 'NY', timezone: 'US/Eastern' },
  { title: 'North Carolina', short: 'NC', timezone: 'US/Eastern' },
  { title: 'North Dakota', short: 'ND', timezone: 'US/Central' },
  { title: 'Ohio', short: 'OH', timezone: 'US/Eastern' },
  { title: 'Oklahoma', short: 'OK', timezone: 'US/Central' },
  { title: 'Oregon', short: 'OR', timezone: 'US/Pacific' },
  { title: 'Pennsylvania', short: 'PA', timezone: 'US/Eastern' },
  { title: 'Rhode Island', short: 'RI', timezone: 'US/Eastern' },
  { title: 'South Carolina', short: 'SC', timezone: 'US/Eastern' },
  { title: 'South Dakota', short: 'SD', timezone: 'US/Central' },
  { title: 'Tennessee', short: 'TN', timezone: 'US/Central' },
  { title: 'Texas', short: 'TX', timezone: 'US/Central' },
  { title: 'Utah', short: 'UT', timezone: 'US/Mountain' },
  { title: 'Vermont', short: 'VT', timezone: 'US/Eastern' },
  { title: 'Virginia', short: 'VA', timezone: 'US/Eastern' },
  { title: 'Washington', short: 'WA', timezone: 'US/Pacific' },
  { title: 'West Virginia', short: 'WV', timezone: 'US/Eastern' },
  { title: 'Wisconsin', short: 'WI', timezone: 'US/Central' },
  { title: 'Wyoming', short: 'WY', timezone: 'US/Mountain' }
];