import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import BasicLayout from './components/BasicLayout';
import LoginPage from './pages/LoginPage';
import LoginFailure from './pages/GoogleLoginFailure';
import LoginSuccess from './pages/GoogleLoginSuccess';
import Cookies from 'js-cookie';

function App() {
  // Set the initial authentication state based on the presence of the token in localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('user'));
  const navigate = useNavigate();

  const handleLogin = () => {
    // Implement your login logic here, and set isAuthenticated to true upon successful login
    navigate("/")
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    // Implement logout logic here, and set isAuthenticated to false upon logout
    setIsAuthenticated(false);
    localStorage.removeItem('user');
    Cookies.remove('session');
    Cookies.remove('session.sig');
    navigate("/login"); // Redirect to the login page after logout
  };

  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route exact path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route exact path="/login/success" element={<LoginSuccess />} />
        <Route exact path="/login/failure" element={<LoginFailure />} />

        {/* Private Routes */}
        {isAuthenticated ? (
          <Route path="/*" element={<BasicLayout onLogout={handleLogout} />} />
        ) : (
          <Route path="/*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </div>
  );
}

export default App;
