import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from "../utils/urls"

export default class PowerBIService {
    async getAccessToken() {

        const response = await axios.get(`${BACKEND_URL}/api/powerbi/accesstoken`)
        if (response.status === 200 && response?.data) {
            const resData = await response.data;

            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async getEmbeddedToken(accessToken) {

        let data = {
            accessToken: accessToken
        }

        let reqOptions = {
            url: `${BACKEND_URL}/api/powerbi/embedtoken`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        }

        const response = await axios.request(reqOptions)
        console.log(response)
        if (response.status === 200 && response?.data) {
            const resData = await response.data;

            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }

    async getConfigInfo() {

        const response = await axios.get(`${BACKEND_URL}/api/powerbi/getPowerBIConfigInfo`)
        if (response.status === 200 && response?.data) {
            const resData = await response.data;

            return resData;

        } else {
            throw json(
                { message: 'Could not fetch events.' },
                {
                    status: 500,
                }
            );
        }
    }
}

export const { getAccessToken, getEmbeddedToken, getConfigInfo } = new PowerBIService()
