import { notification } from "antd";

export const notify = (message, description, type = 'success') => {
    if (type === 'error') {
        notification.error({
            message,
            duration: 3,
            placement: 'topRight',
            ...(description && { description})
        })
    } else {   
        notification.success({
            message,
            duration: 3,
            placement: 'topRight',
            ...(description && { description })
        });
    }
};