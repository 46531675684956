import axios from 'axios'
import { json } from 'react-router-dom'
import { BACKEND_URL } from '../utils/urls'

export default class ParticipationMatrixService {
  static async getParticipationMatrixData(params) {
    let reqOptions = {
      url: `${BACKEND_URL}/api/getParticipationMatrixData`,
      method: 'GET',
      params: {
        search: params?.search,
        page: params.page,
        limit: params.limit
      }
    }

    try {
      let response = await axios.request(reqOptions)

      if (response.status === 200 && response.data) {
        const resData = await response.data
        return resData
      }
    } catch (error) {
      throw error
    }
  }

  static async uploadCredentialingSummary(file) {
    try {
      let headersList = {
        "Content-Type": "multipart/form-data"
      }

      let reqOptions = {
        url: `${BACKEND_URL}/api/uploadParticipationMatrix`,
        method: "POST",
        headers: headersList,
        data: file,
      }

      let response = await axios.request(reqOptions);
      if (response.status === 200 && response.data) {
        const resData = await response.data;
        return resData;
      }
    } catch (error) {
      throw error
    }
  }
}
