import jsPDF from "jspdf";
import { notify } from "./notify";

export const generateScreeningPdf = (record) => {
    const doc = new jsPDF();
    const fontSizeNormal = 13; // Font size for normal text
    let yPos = 20;
    let xPos = 20;
    const PAGE_HEIGHT = 300;
    const MAX_WIDTH = 420
    const LINE_HEIGHT = 10

    doc.setFontSize(fontSizeNormal);
    const shouldBreakPage = (additionalHeight) => {
        return yPos + additionalHeight > PAGE_HEIGHT;
    };

    // Function to handle page break
    const handlePageBreak = () => {
        doc.addPage();
        yPos = 20;
    };

    const wrapText = (text, maxWidth, lineHeight) => {
        const words = text.split(' ');
        let line = '';
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const width = doc.getStringUnitWidth(line + word) * doc.internal.getFontSize();
            if (width > maxWidth) {
                doc.text(line, xPos, yPos);
                yPos += lineHeight;
                line = word + ' ';
            } else {
                line += word + ' ';
            }
        }
        doc.text(line, xPos, yPos);
    };

    doc.setFont("helvetica", "bold");
    doc.text("First Name", xPos, yPos);
    yPos += 10
    doc.setFont("helvetica", "normal");
    doc.text(`${record.firstName}`, xPos, yPos);
    yPos += 15

    doc.setFont("helvetica", "bold");
    doc.text("Last Name", xPos, yPos);
    yPos += 10
    doc.setFont("helvetica", "normal");
    doc.text(`${record.lastName}`, xPos, yPos);
    yPos += 15

    if (record.dob) {
        doc.setFont("helvetica", "bold");
        doc.text("Date Of Birth", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.dob}`, xPos, yPos);
        yPos += 15
    }



    doc.setFont("helvetica", "bold");
    doc.text("Email", xPos, yPos);
    yPos += 10
    doc.setFont("helvetica", "normal");
    doc.text(`${record.email}`, xPos, yPos);
    yPos += 15

    if (record.phone) {
        doc.setFont("helvetica", "bold");
        doc.text("Phone", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.phone}`, xPos, yPos);
        yPos += 15
    }

    if (record.pregnancyStatus) {
        doc.setFont("helvetica", "bold");
        doc.text("Are you currently?", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.pregnancyStatus}`, xPos, yPos);
        yPos += 15
    }

    if (record.babyDate) {
        doc.setFont("helvetica", "bold");
        doc.text("Baby's Date of Birth or Due Date", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.babyDate}`, xPos, yPos);
        yPos += 15
    }


    if (record.clinician) {
        doc.setFont("helvetica", "bold");
        doc.text("Which provider will you be seeing today?", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.clinician}`, xPos, yPos);
        yPos += 15
    }

    if (record.epdsScore) {
        doc.setFont("helvetica", "bold");
        doc.text("EPDS Score", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.epdsScore}`, xPos, yPos);
        yPos += 15
    }

    if (record.phq9Score) {
        doc.setFont("helvetica", "bold");
        doc.text("PHQ9 Score", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.phq9Score}`, xPos, yPos);
        yPos += 15
    }

    if (record.gad7Score) {
        doc.setFont("helvetica", "bold");
        doc.text("GAD7 Score", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.gad7Score}`, xPos, yPos);
        yPos += 15
    }

    if (record.gdsScore) {
        doc.setFont("helvetica", "bold");
        doc.text("GDS Score", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.gdsScore}`, xPos, yPos);
        yPos += 15
    }


    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("How you have felt IN THE PAST 7 DAYS, not just how you feel today ? ", xPos, yPos);
    yPos += 15


    doc.setFontSize(fontSizeNormal);
    if (record.download && record.download.phq9Answers) {
        for (const question in record.download.phq9Answers) {
            const answer = record.download.phq9Answers[question];
            if (shouldBreakPage(20)) {
                handlePageBreak(); // Perform page break
            }

            doc.setFont("helvetica", "bold");
            wrapText(`${question}`, MAX_WIDTH, LINE_HEIGHT)
            yPos += 10;
            doc.setFont("helvetica", "normal");
            doc.text(`${answer} `, xPos, yPos);
            yPos += 10;
        }
    }

    if (record.download && record.download.gad7Answers) {

        for (const question in record.download.gad7Answers) {
            const answer = record.download.gad7Answers[question];
            if (shouldBreakPage(20)) {
                handlePageBreak(); // Perform page break
            }
            doc.setFont("helvetica", "bold");
            wrapText(`${question}`, MAX_WIDTH, LINE_HEIGHT)
            yPos += 10;
            doc.setFont("helvetica", "normal");
            doc.text(`${answer}`, xPos, yPos);
            yPos += 10;
        }
    }

    if (record.download && record.download.epdsAnswers) {

        for (const question in record.download.epdsAnswers) {
            const answer = record.download.epdsAnswers[question];
            if (shouldBreakPage(20)) {
                handlePageBreak(); // Perform page break
            }
            doc.setFont("helvetica", "bold");
            wrapText(`${question}`, MAX_WIDTH, LINE_HEIGHT)
            yPos += 10;
            doc.setFont("helvetica", "normal");
            doc.text(`${answer}`, xPos, yPos);
            yPos += 10;
        }
    }

    if (record.download && record.download.gdsAnswers) {

        for (const question in record.download.gdsAnswers) {
            const answer = record.download.gdsAnswers[question];
            if (shouldBreakPage(20)) {
                handlePageBreak(); // Perform page break
            }
            doc.setFont("helvetica", "bold");
            wrapText(`${question}`, MAX_WIDTH, LINE_HEIGHT)
            yPos += 10;
            doc.setFont("helvetica", "normal");
            doc.text(`${answer}`, xPos, yPos);
            yPos += 10;
        }
    }

    if (record.anxietyAnswers) {
        if (shouldBreakPage(20)) {
            handlePageBreak(); // Perform page break
        }
        yPos += 5
        doc.setFontSize(15);
        doc.setFont("helvetica", "bold");
        doc.text("How you have felt IN THE PAST 7 DAYS, not just how you feel today ? ", xPos, yPos);
        yPos += 15
        doc.setFontSize(fontSizeNormal);
        for (const question in record.anxietyAnswers) {
            const answer = record.anxietyAnswers[question];
            if (shouldBreakPage(20)) {
                handlePageBreak(); // Perform page break
            }
            doc.setFont("helvetica", "bold");
            wrapText(`${question}`, MAX_WIDTH, LINE_HEIGHT)
            yPos += 10;
            doc.setFont("helvetica", "normal");
            doc.text(`${answer}`, xPos, yPos);
            yPos += 10;
        }
    }

    if (record.contactMethod) {
        if (shouldBreakPage(20)) {
            handlePageBreak(); // Perform page break
        }
        doc.setFont("helvetica", "bold");
        doc.text("How would you like us to contact you?", xPos, yPos);
        yPos += 10
        doc.setFont("helvetica", "normal");
        doc.text(`${record.contactMethod}`, xPos, yPos);
        yPos += 15
    }


    return doc
    // doc.save(`report-${record.id}.pdf`);
    // notify('Downloaded Assessment!', 'Please check your downloads!')
};

