/* eslint-disable react/prop-types */
import { useCallback, useEffect, useState } from 'react'
import { formatInTimeZone, toDate } from 'date-fns-tz'
import { useField, useForm } from 'react-final-form'
import { z } from 'zod'
import validateField from '../../../utils/validation'
import Calendar from './Calendar'
import AvailableTime from './AvailableTime'
import FieldError from './FieldError'
import "./BookingCalendar.css"
import FormControl from './FormControl'
import Row from './Row'
import Column from './Column'

const selectedDateSubscription = {
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  touched: true,
  value: true
}
const validateFields = []
const schema = z.object({
  day: z.string(),
  time: z.string()
}, {
  required_error: 'Please select the date and time for appointment.'
})

const BookingCalendar = ({ followupSlots, timezone, duration }) => {
  const form = useForm()
  const [date, setDate] = useState(new Date())

  const { input: { onChange, value }, meta } = useField('selectedDate', {
    subscription: selectedDateSubscription,
    validate: validateField(schema),
    validateFields
  })
  const [selectedDay, setSelectedDay] = useState()
  const setSelectedTime = useCallback(
    (time) => selectedDay && time && onChange({ day: selectedDay, time }),
    [selectedDay, onChange]
  )

  useEffect(() => {
    setSelectedDay()

    form.batch(() => {
      form.change('selectedDate')
      form.resetFieldState('selectedDate')
    })
  }, [date, form])

  // TODO :: replace 'availableClinicianFollowUpDates' with 'followupSlots.slots' when CORS issue is fixed
  const convertedAvailableDates = followupSlots?.slots
    .reduce((convertedDates, { day, time }) => {
      time.forEach((hours) => {
        const amdDate = toDate(`${day} ${hours}`, { timeZone: 'US/Eastern' })
        const [convertedDay, convertedTime] = formatInTimeZone(amdDate, timezone, 'yyyy-MM-dd HH:mm').split(' ')
        const dateIndex = convertedDates.findIndex((convertedDate) => (
          convertedDate.day === convertedDay
        ))

        if (dateIndex !== -1) {
          convertedDates[dateIndex].time.push(convertedTime)
        } else {
          convertedDates.push({ day: convertedDay, time: [convertedTime] })
        }
      })

      return convertedDates
    }, [])

  return (
    <>
      <FormControl className='bookingCalendar'>
        <Row>
          <Column>
            <p className='label'>Appointment Date<span style={{ color: 'red', marginBottom: '-5rem' }}>*</span></p>
            <Calendar
              availableDays={convertedAvailableDates?.map(({ day }) => day)}
              date={date}
              selectedDay={selectedDay}
              setDate={setDate}
              setSelectedDay={setSelectedDay}
            />
          </Column>
          <Column>
            <p className='label'>Session Duration: {duration} Minutes<span style={{ color: 'red', marginBottom: '-5rem' }}>*</span></p>
            <AvailableTime
              availableTime={convertedAvailableDates?.find(({ day }) => day === selectedDay)?.time}
              selectedTime={value?.time}
              setSelectedTime={setSelectedTime}
            />
          </Column>
        </Row>
      </FormControl >
      <FieldError {...meta} />
    </>
  )
}

export default BookingCalendar
