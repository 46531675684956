import AMDzipCode from "../components/utilityComponents/AMDzipCode";
import AddInsurances from "../components/utilityComponents/AddInsurance";
import AddState from "../components/utilityComponents/AddState";

function Utilities() {
    document.title = "LunaJoy Dashboard";


    return (
     
            <div id="dashboard-container">
        <AMDzipCode />
        <br/>
        <AddState/>
        <br/>
        <AddInsurances/>
        </div>
        
    )

}

export default Utilities;
