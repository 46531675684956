import { Modal } from "antd";
import React from "react";
import wip from '../../../assets/WIP.png'

const ComingSoonModal = ({ isComingSoonModalOpen, handleCancel }) => {
  return (
    <Modal
      title=""
      open={isComingSoonModalOpen}
      onCancel={handleCancel}
      footer={null}
      maskClosable={true}
      centered={true}
    >

      <div style={{ textAlign: "center" }}>
        <img src={wip} alt="WIP" width={100} height={100} style={{ marginBlock: '1rem' }} />
        <h3>
          This feature is coming soon...
        </h3>
      </div>
    </Modal>
  );
};

export default ComingSoonModal;
